import { GenericType } from "./index";

export type DropdownConfig = {
  inputLabelText:
    | "Project"
    | "Company"
    | "Project Status"
    | "Country"
    | "Factory"
    | "Factory Functions"
    | "Equipment Type"
    | "Factory Status"
    | "Wind project"
    | "Wind project status"
    | "PPA type"
    | "Buyer"
    | "Seller"
    | "Supplier"
    | "Wind farm"
    | "Contract status"
    | "Turbine model"
    | "Foundation type"
    | "Cable type"
    | "Output current"
    | "Substation"
    | "Components"
    | "Technology type"
    | "Floating technology"
    | "Vessel name"
    | "Vessel owner"
    | "Contracted equipment type";
  options: { id: string | number; value: string }[];
};

export type DateConfig = {
  inputLabelText: "Date" | "Contract award date" | "Start date" | "End date";
  format: string;
};

export type NumericConfig = {
  inputLabelText: "Voltage kV";
  options: [number, number];
};

export type FilterConfig = DropdownConfig | DateConfig | NumericConfig;

export const DROPDOWN_LABELS = [
  "Project",
  "Company",
  "Project Status",
  "Country",
  "Factory",
  "Factory Functions",
  "Equipment Type",
  "Factory Status",
  "Wind project",
  "Wind project status",
  "PPA type",
  "Buyer",
  "Seller",
  "Supplier",
  "Wind farm",
  "Contract status",
  "Turbine model",
  "Foundation type",
  "Cable type",
  "Output current",
  "Substation",
  "Components",
  "Technology type",
  "Floating technology",
  "Vessel name",
  "Vessel owner",
  "Contracted equipment type",
];

export const DATE_LABELS = [
  "Date",
  "Contract award date",
  "Start date",
  "End date",
];
export const NUMERIC_LABELS = ["Voltage kV"];

export const isDropdownConfig = (
  options: GenericType[],
): options is { id: string | number; value: string }[] => {
  return (
    Array.isArray(options) &&
    options.every(
      (option) =>
        typeof option.id === "string" ||
        (typeof option.id === "number" && typeof option.value === "string"),
    )
  );
};

export const isNumericConfig = (
  options: number[],
): options is [number, number] => {
  return (
    Array.isArray(options) &&
    options.length === 2 &&
    typeof options[0] === "number" &&
    typeof options[1] === "number"
  );
};

export const normalizeDateKey = (key: string): string => {
  const mapping: Record<string, string> = {
    contractAwardDate: "Contract award date",
    date: "Date",
    startDate: "Start date",
    endDate: "End date",
  };
  return mapping[key] || key;
};

export const normalizeNumericKey = (key: string): string => {
  const mapping: Record<string, string> = {
    voltageKV: "Voltage kV",
  };
  return mapping[key] || key;
};
