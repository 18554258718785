import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@esgian/esgianui";
import { useQuery } from "@tanstack/react-query";
import { CoreScaleOptions, Scale } from "chart.js/dist/types";
import zoomPlugin from "chartjs-plugin-zoom";
import moment from "moment";

import { api } from "../../api/api";
import { getSourceText } from "../../helper/powerGenSoureRef";
import { safelyCallApi } from "../../helper/requestWrapper";
import { useSelector } from "../../hooks/use-selector";
import { useLookupQueries } from "../../hooks/useQueries/useLookupQueries";
import { usePowerGenQueries } from "../../hooks/useQueries/usePowerGenQueries";
import { useTheme } from "../../hooks/useTheme";
import {
  getPowerOutputCompareWindProjects,
  getPowerOutputPeriodType,
  getPowerOutputTimePeriod,
  getPowerOutputWindProject,
} from "../../store/selector/powerOutput";
import { POWER_GEN_FILTERS } from "../../store/slice/powerOutput";
import { GenericType } from "../../types";
import { AxisTitlePlugin, HoverLinePlugin } from "../Charts";
import CanvasTimeSeriesChart from "../Charts/CanvasTimeSeriesChart";
import { NoDataPlugin } from "../Charts/plugins/NoDataPlugin";

// Define the structure for wind farm data

type Prop = {
  selectedTab?: string;
};

export const PowerOutputHistoricalCapacityAreaChart: FC<Prop> = ({
  selectedTab,
}) => {
  const chartRef = useRef(undefined);
  const [isStacked, setIsStacked] = useState(false);
  const { lookupProjectsObject } = useLookupQueries();
  const selectedPeriodType = useSelector(getPowerOutputPeriodType);
  const selectedTimePeriod = useSelector(getPowerOutputTimePeriod);
  const selectedProject = useSelector(getPowerOutputWindProject);
  const compareWindProjects = useSelector(getPowerOutputCompareWindProjects);
  const { powerDataFullProjectQuery } = usePowerGenQueries();
  const isCapacity = selectedTab === "capacity";
  const {
    theme: {
      palette: {
        text: { primary },
        charts: { tenColors, backgroundHover },
      },
    },
  } = useTheme();

  useEffect(() => {
    if (isCapacity && isStacked) {
      setIsStacked(false);
    }
  }, [isCapacity, isStacked]);

  const seriesDataQuery = useQuery({
    queryKey: [
      "powerGenChartData",
      {
        selectedPeriodType,
        selectedTimePeriod,
        selectedProject,
        compareWindProjects,
      },
    ],
    enabled: !!selectedProject && !!selectedPeriodType && !!selectedTimePeriod,
    placeholderData: { seriesData: [], labels: [] },
    queryFn: () => {
      const ids: number[] = [];
      if (selectedProject) {
        ids.push(selectedProject.id);
      }
      if (compareWindProjects.length) {
        compareWindProjects.forEach(({ id }) => ids.push(id));
      }
      let historyRollup = "day";
      const body: GenericType = {};
      const periodTypes = POWER_GEN_FILTERS.PERIOD_TYPE_OPTIONS;

      if (selectedPeriodType?.id === periodTypes[1].id) {
        historyRollup = selectedTimePeriod?.unit?.slice(0, -1) ?? "";
      } else {
        if (
          !!selectedTimePeriod &&
          [1, 2, 3, 4].includes(selectedTimePeriod?.id) // until 3 months
        ) {
          historyRollup = "hour";
        }
        const end = moment().subtract(1, "days");
        body.startTimestamp = end
          .clone()
          .subtract(selectedTimePeriod?.number, selectedTimePeriod?.unit);
        body.endTimestamp = end;
      }

      return safelyCallApi(
        api.powerOutputAggregated.getPowerOutputAggregated({
          projectIds: ids,
          includeHistorical: true,
          aggregationLevel: historyRollup === "hour" ? "hour" : "day",
          historyRollup: historyRollup,
          ...body,
        }),
      )
        .then(({ status, data }: GenericType) => {
          if (status !== 200) return { seriesData: [], labels: [] };
          const res: GenericType[] = [];
          const labels = new Set();
          ids.forEach((val) => {
            if (!val) return;
            if (!(val in data)) {
              return;
            }
            const { projectId, historicalData } = data[val];
            if (!projectId || !historicalData) return;

            const tempSeries: GenericType = {
              projectId: Number(projectId),
              capacityFactor: [],
              generationOutput: [],
            };

            Object.keys(historicalData).forEach((date: string) => {
              const values = historicalData[date];
              const dateValue = moment(date).valueOf();
              labels.add(dateValue);
              tempSeries.capacityFactor.push({
                x: dateValue,
                y: values.count ? values.capacityFactor : null,
              });
              tempSeries.generationOutput.push({
                x: dateValue,
                y: values.count ? values.generationOutput : null,
              });
            });
            tempSeries.capacityFactor = [...tempSeries.capacityFactor].sort(
              (a, b) => a.x - b.x,
            );
            tempSeries.generationOutput = [...tempSeries.generationOutput].sort(
              (a, b) => a.x - b.x,
            );

            res.push(tempSeries);
          });

          return {
            seriesData: res,
            labels: (Array.from(labels) as number[]).sort((a, b) => a - b),
          };
        })
        .catch(() => {
          return { seriesData: [], labels: [] };
        });
    },
  });

  const series = useMemo(() => {
    const { seriesData } = seriesDataQuery.data || {};
    if (!lookupProjectsObject) return [];
    if (!seriesData?.length) return [];

    // Find the start date
    const startDateChart = Math.min(
      ...seriesData.map((item) =>
        Math.min(
          ...item.generationOutput.map(
            (point: { x: number; y: number }) => point.x,
          ),
        ),
      ),
    );

    return seriesData.map((item, i) => {
      // Create an array to hold the updated data with missing dates filled in
      const updatedData: { x: number; y: number }[] = []; // Keeping y strictly as a number (using NaN for missing data)
      let currentDate = moment(startDateChart).clone();

      // Fill in missing data points for each series
      const data = isCapacity ? item.capacityFactor : item.generationOutput;

      data.forEach((point: { x: number; y: number }) => {
        const currentPointDate = moment(point.x); // Convert point's date to moment object
        if (selectedTimePeriod?.responseKey === "monthly") {
          const monthsDifference = currentPointDate.diff(currentDate, "months");
          for (
            let monthIndex = 1;
            monthIndex < monthsDifference;
            monthIndex++
          ) {
            const missingDate = currentDate
              .clone()
              .add(monthIndex, "months")
              .startOf("month");
            updatedData.push({ x: missingDate.valueOf(), y: NaN }); // Use NaN for missing data
          }
        } else if (selectedTimePeriod?.responseKey === "quarterly") {
          const quartersDifference = currentPointDate.diff(
            currentDate,
            "quarters",
          );
          for (
            let quarterIndex = 1;
            quarterIndex < quartersDifference;
            quarterIndex++
          ) {
            const missingDate = currentDate
              .clone()
              .add(quarterIndex, "quarters")
              .startOf("quarter");
            updatedData.push({ x: missingDate.valueOf(), y: NaN }); // Use NaN for missing data
          }
        } else if (selectedTimePeriod?.responseKey === "yearly") {
          const yearsDifference = currentPointDate.diff(currentDate, "years");
          for (let yearIndex = 1; yearIndex < yearsDifference; yearIndex++) {
            const missingDate = currentDate
              .clone()
              .add(yearIndex, "years")
              .startOf("year");
            updatedData.push({ x: missingDate.valueOf(), y: NaN }); // Use NaN for missing data
          }
        } else if (
          selectedTimePeriod?.responseKey === "threeMonths" ||
          selectedTimePeriod?.responseKey === "oneMonths" ||
          selectedTimePeriod?.responseKey === "oneWeeks"
        ) {
          const hoursDifference = currentPointDate.diff(currentDate, "hours");
          for (let hourIndex = 1; hourIndex < hoursDifference; hourIndex++) {
            const missingDate = currentDate.clone().add(hourIndex, "hours");
            updatedData.push({ x: missingDate.valueOf(), y: NaN }); // Use NaN for missing data
          }
        } else {
          // Default case (fill missing days)
          const daysDifference = currentPointDate.diff(currentDate, "days");
          for (let dayIndex = 1; dayIndex < daysDifference; dayIndex++) {
            const missingDate = currentDate.clone().add(dayIndex, "days");
            updatedData.push({ x: missingDate.valueOf(), y: NaN }); // Use NaN for missing data
          }
        }

        // Add the current point
        updatedData.push({ x: point.x, y: point.y });

        // Update lastDate to current point's x
        currentDate = moment(point.x);
      });

      const obj = {
        label: lookupProjectsObject[item.projectId].name,
        type: "line",
        unit: isCapacity ? "%" : "GWh",
        projectId: item.projectId,
        borderColor: tenColors[i],
        spanGaps: false,
        data: updatedData,
        backgroundColor: tenColors[i],
        fill: false,
      };

      if (isStacked) {
        return {
          ...obj,
          type: "line",
          stack: 1,
          backgroundColor: tenColors[i] + "80",
          borderSkipped: true,
          fill: true,
        };
      }

      return obj;
    });
  }, [
    isStacked,
    seriesDataQuery.data,
    lookupProjectsObject,
    tenColors,
    isCapacity,
    selectedTimePeriod,
  ]);

  // TODO spec out options
  const renderChartLabels = useCallback(
    function (
      this: Scale<CoreScaleOptions>,
      tickValue: string | number,
    ): string | number | null | undefined {
      if (!selectedTimePeriod) return "";
      const currentDate = moment(this.getLabelForValue(tickValue as number));
      return currentDate.format(selectedTimePeriod.chartFormat);
    },
    [selectedTimePeriod],
  );

  const plugins = useMemo(() => {
    return [
      new AxisTitlePlugin([
        {
          text: selectedTab === "capacity" ? "%" : "GWh",
          fontSize: "10px",
          fontColor: primary,
          YPosition: "top",
          XPosition: "left",
        },
      ]).getPlugin(),
      new HoverLinePlugin({ lineColor: primary }).getPlugin(),
      new NoDataPlugin({
        backgroundColor: backgroundHover,
        textColor: primary,
      }).getPlugin(),
      zoomPlugin,
    ];
  }, [primary, backgroundHover, selectedTab]);

  const sourceData = useMemo(() => {
    if (!selectedProject) return {};
    if (!powerDataFullProjectQuery.data) return {};

    const queryData = powerDataFullProjectQuery.data as Record<
      string,
      GenericType
    >;
    const project = queryData[selectedProject.id];

    if (!project) return;
    return {
      sourceName: project.sourceName,
      firstRecordTime: project.firstRecordTime,
      countryName: project.countryName,
    };
  }, [powerDataFullProjectQuery.data, selectedProject]);

  const sourceDisclaimer = getSourceText(
    sourceData?.sourceName,
    sourceData?.firstRecordTime,
    sourceData?.countryName,
  );

  return (
    <Box
      sx={{
        background: ({ palette }: GenericType) => palette.background.paper,
        p: 2,
        borderRadius: 4,
        color: ({ palette }: GenericType) => palette.text.primary,
      }}
    >
      <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
          <Typography variant="h6">
            {`${isCapacity ? "Capacity factor" : "Power generation"} & key events`}
          </Typography>
          {selectedTab === "output" && (
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={isStacked}
                  onChange={() => setIsStacked((prev) => !prev)}
                />
              }
              label={<Typography variant="body2">Show stacked</Typography>}
            />
          )}
        </Stack>

        <Stack direction="column" spacing={1}>
          <CanvasTimeSeriesChart
            withKeyEvents={true}
            stackedBar={isStacked}
            key={`${selectedTab}-chart`}
            tooltipTitleFormat={
              selectedTimePeriod?.chartFormat ?? "YYYY/MMM/DD"
            }
            plugins={plugins}
            customLabelRender={renderChartLabels}
            chartRef={chartRef}
            loading={seriesDataQuery.isFetching}
            series={series}
            type="line"
            id="chart-capacity"
            isCapFactor={isCapacity}
          />
          <Typography variant="subtitle2">{sourceDisclaimer}</Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
