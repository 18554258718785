import { FilterItemType } from "../types";

import { FilterConfig } from "./filterBase";

export class DropdownFilter extends FilterConfig {
  constructor(config: {
    inputLabelText: string;
    options: { id: string | number; value: string }[];
  }) {
    super({
      type: FilterItemType.Autocomplete,
      inputLabelText: config.inputLabelText,
      labelKey: "value",
      options: config.options,
    });
  }

  getConfig() {
    return {
      type: this.type,
      inputLabelText: this.inputLabelText,
      labelKey: this.labelKey,
      options: this.options,
    };
  }
}
