import {
  DateConfig,
  DropdownConfig,
  FilterConfig,
  NumericConfig,
} from "../types/filters";

import { DateFilter } from "./dateFilter";
import { DropdownFilter } from "./dropdownFilter";
import { NumericFilter } from "./numericFilter";

export class FilterFactory {
  private static filtersMap = {
    Project: DropdownFilter,
    Company: DropdownFilter,
    "Project Status": DropdownFilter,
    Date: DateFilter,
    Country: DropdownFilter,
    Factory: DropdownFilter,
    "Factory Functions": DropdownFilter,
    "Equipment Type": DropdownFilter,
    "Factory Status": DropdownFilter,
    "Wind project": DropdownFilter,
    "Wind project status": DropdownFilter,
    "PPA type": DropdownFilter,
    Buyer: DropdownFilter,
    Seller: DropdownFilter,
    "Contract award date": DateFilter,
    Supplier: DropdownFilter,
    "Wind farm": DropdownFilter,
    "Contract status": DropdownFilter,
    "Turbine model": DropdownFilter,
    "Foundation type": DropdownFilter,
    "Cable type": DropdownFilter,
    "Voltage kV": NumericFilter,
    "Output current": DropdownFilter,
    Substation: DropdownFilter,
    Components: DropdownFilter,
    "Technology type": DropdownFilter,
    "Floating technology": DropdownFilter,
    "Vessel name": DropdownFilter,
    "Vessel owner": DropdownFilter,
    "Start date": DateFilter,
    "End date": DateFilter,
    "Contracted equipment type": DropdownFilter,
  };

  // Factory method to create the filter based on the inputLabelText
  static createFilter(config: FilterConfig) {
    const FilterClass = this.filtersMap[config.inputLabelText];
    if (!FilterClass) {
      throw new Error(
        `Filter for inputLabelText "${config.inputLabelText}" not found.`,
      );
    }

    if (FilterClass === DropdownFilter) {
      // TypeScript understands that config is DropdownConfig here
      const dropdownConfig = config as DropdownConfig; // Narrowing down the type

      if (!dropdownConfig.options || dropdownConfig.options.length === 0) {
        throw new Error("DropdownFilter requires options.");
      }
      return new FilterClass(dropdownConfig);
    }

    if (FilterClass === DateFilter) {
      // TypeScript understands that config is DateConfig here
      const dateConfig = config as DateConfig;
      if (!dateConfig.format) {
        throw new Error("DateFilter requires a format.");
      }
      // Return the DateFilter instance with format
      return new FilterClass(dateConfig);
    }

    if (FilterClass === NumericFilter) {
      // TypeScript understands that config is NumericConfig here
      const numericConfig = config as NumericConfig;
      if (!numericConfig.options) {
        throw new Error("NumericFilter requires options.");
      }
      return new FilterClass(numericConfig);
    }
    throw new Error("Unsupported filter type.");
  }
}
