import { FilterItemType } from "../types";

import { FilterConfig } from "./filterBase";

export class NumericFilter extends FilterConfig {
  options: [number, number];

  constructor(config: { inputLabelText: string; options: [number, number] }) {
    super({
      type: FilterItemType.Slider,
      inputLabelText: config.inputLabelText,
      labelKey: "value",
    });

    if (!Array.isArray(config.options) || config.options.length !== 2) {
      throw new Error(
        "NumericFilter options must be an array of exactly two numbers.",
      );
    }

    this.options = config.options;
  }

  getConfig() {
    return {
      type: this.type,
      inputLabelText: this.inputLabelText,
      labelKey: this.labelKey,
      options: this.options,
    };
  }
}
