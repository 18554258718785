import { useMemo } from "react";
import { Box, Table, Typography } from "@esgian/esgianui";

import {
  calculateFilterCount,
  filterDataBasedOnDynamicKeys,
} from "../../helper/fllter";
import { isMatch } from "../../helper/isMatch";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getCableLayList,
  getIsLoading,
  getSelectedFilter,
  getSortingInfo,
  getVisibleColumns,
} from "../../store/selector/cableLay";
import {
  getRowsPerPage,
  getSearchText,
  getThemeMode,
  getUser,
} from "../../store/selector/common";
import { setSortingInfo, setVisibleColumns } from "../../store/slice/cableLay";
import {
  setRowsPerPage,
  setSelectedCableLayId,
} from "../../store/slice/common";
import { CableLay, GenericType, IdNamePair } from "../../types";
import { FilterBtn } from "../FilterBtn";
import { Loading } from "../Loading";

export const CableLayList = () => {
  const dispatch = useDispatch();
  const themeMode = useSelector(getThemeMode);
  const isLoading = useSelector(getIsLoading);
  const searchText = useSelector(getSearchText);
  const sortingInfo = useSelector(getSortingInfo);
  const cableLayList = useSelector(getCableLayList);
  const selectedFilter = useSelector(getSelectedFilter);
  const rowsPerPage = useSelector(getRowsPerPage);
  const visibleColumns = useSelector(getVisibleColumns);
  const user = useSelector(getUser);

  const COLUMNS = useMemo(
    () => [
      {
        label: "Vessel name",
        name: "vesselName",
        options: {
          display: visibleColumns.includes("vesselName"),
          setCellProps: () => ({ style: { cursor: "pointer" } }),
          customBodyRender: (value: IdNamePair) => value || "N/A",
        },
      },
      {
        label: "Owner",
        name: "owner",
        options: { display: visibleColumns.includes("owner") },
      },
      {
        label: "Design",
        name: "design",
        options: { display: visibleColumns.includes("design") },
      },
      {
        label: "Maximum cable capacity (t)",
        name: "maxCableCapacity",
        options: { display: visibleColumns.includes("maxCableCapacity") },
      },
      {
        label: "Date of delivery",
        name: "dateOfDelivery",
        options: { display: visibleColumns.includes("dateOfDelivery") },
      },
      {
        label: "Newbuild",
        name: "newbuild",
        options: { display: visibleColumns.includes("newbuild") },
      },
      {
        label: "Vessel Type",
        name: "vesselType",
        options: { display: visibleColumns.includes("vesselType") },
      },
      {
        label: "Status",
        name: "status",
        options: { display: visibleColumns.includes("status") },
      },
      {
        label: "Vessel capability",
        name: "vesselCapability",
        options: { display: visibleColumns.includes("vesselCapability") },
      },
      {
        label: "IMO",
        name: "imo",
        options: { display: visibleColumns.includes("imo") },
      },
      {
        label: "Flag",
        name: "flag",
        options: { display: visibleColumns.includes("flag") },
      },
      {
        label: "Constructed at yard",
        name: "constructedYard",
        options: { display: visibleColumns.includes("constructedYard") },
      },
      {
        label: "MMSI",
        name: "mmsi",
        options: { display: visibleColumns.includes("mmsi") },
      },
      {
        label: "Length (m)",
        name: "length",
        options: { display: visibleColumns.includes("length") },
      },
      {
        label: "Width (m)",
        name: "width",
        options: { display: visibleColumns.includes("width") },
      },
      {
        label: "Draught (m)",
        name: "draught",
        options: { display: visibleColumns.includes("draught") },
      },
      {
        label: "Maximum deck strength (t/m2)",
        name: "maxDeckStrength",
        options: { display: visibleColumns.includes("maxDeckStrength") },
      },
      {
        label: "Maximum speed (kn)",
        name: "maxSpeed",
        options: { display: visibleColumns.includes("maxSpeed") },
      },
      {
        label: "Maxiumum POB",
        name: "maxPob",
        options: { display: visibleColumns.includes("maxPob") },
      },
      {
        label: "Primary turntable capacity (t)",
        name: "primaryTurntableCapacity",
        options: {
          display: visibleColumns.includes("primaryTurntableCapacity"),
        },
      },
      {
        label: "Primary outer diameter (m)",
        name: "primaryOuterDiameter",
        options: { display: visibleColumns.includes("primaryOuterDiameter") },
      },
      {
        label: "Primary core diameter (m)",
        name: "primaryCoreDiameter",
        options: { display: visibleColumns.includes("primaryCoreDiameter") },
      },
      {
        label: "Primary core height (m)",
        name: "primaryCoreHeight",
        options: { display: visibleColumns.includes("primaryCoreHeight") },
      },
      {
        label: "Primary turntable capacity (t)",
        name: "primaryTurntableCapacity",
        options: {
          display: visibleColumns.includes("primaryTurntableCapacity"),
        },
      },
      {
        label: "Secondary turntable capacity (m)",
        name: "secondaryTurntableCapacity",
        options: {
          display: visibleColumns.includes("secondaryTurntableCapacity"),
        },
      },
      {
        label: "Secondary core height (m)",
        name: "secondaryCoreHeight",
        options: { display: visibleColumns.includes("secondaryCoreHeight") },
      },
      {
        label: "Max laying velocity (m/s)",
        name: "maxLayingVelocity",
        options: { display: visibleColumns.includes("maxLayingVelocity") },
      },
      {
        label: "Bollard pull (t)",
        name: "bollardPull",
        options: { display: visibleColumns.includes("bollardPull") },
      },
      {
        label: "Crane type",
        name: "craneType",
        options: { display: visibleColumns.includes("craneType") },
      },
      {
        label: "Crane model",
        name: "craneModel",
        options: { display: visibleColumns.includes("craneModel") },
      },
    ],
    [visibleColumns],
  );

  const data = useMemo(() => {
    if (!cableLayList || !cableLayList?.length) return [];
    let list = filterDataBasedOnDynamicKeys(
      cableLayList,
      selectedFilter,
    ) as CableLay[];
    if (searchText?.length && list?.length) {
      list = list?.filter((item: CableLay) => isMatch(item, [searchText]));
    }
    return list;
  }, [cableLayList, searchText, selectedFilter]);

  const hasExportRight =
    Array.isArray(user?.profile?.permissionList) &&
    user?.profile?.permissionList?.includes("WIND_dataExports");

  return (
    <Box sx={{ px: 2, maxWidth: "calc(100vw - 265px)" }}>
      {!isLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body1" sx={{ py: 2 }}>
              {`Displaying ${data?.length} Cable lay vessels`}
            </Typography>
            <FilterBtn count={calculateFilterCount(selectedFilter)} />
          </Box>

          <Table
            app="wind"
            columnDefs={[]}
            columns={COLUMNS}
            data={data}
            elevation={0}
            mode={themeMode}
            options={{
              download: hasExportRight,
              search: false,
              downloadOptions: {
                filename: `cable_lay_data.csv`,
              },
              expandableRows: false,
              filter: false,
              filterType: "multiselect",
              hideToolbarMargin: false,
              pagination: true,
              responsive: "vertical",
              rowsPerPage,
              onChangeRowsPerPage: (numberOfRows: number) => {
                dispatch(setRowsPerPage(numberOfRows));
              },
              onRowClick: (_: number, rowMeta: GenericType) => {
                const { dataIndex }: GenericType = rowMeta;
                const item: GenericType = data[dataIndex];
                dispatch(setSelectedCableLayId(item.imo));
              },
              rowsPerPageOptions: [25, 50, 100],
              selectableRows: "none",

              sortOrder: sortingInfo,
              onColumnSortChange: (name: string, direction: string) => {
                dispatch(setSortingInfo({ name, direction }));
              },
              showResponsive: true,
              tableBodyHeight: "calc(100vh - 310px)",
              toolbar: true,
              print: false,
              viewColumns: true,
              onViewColumnsChange: (c: string | string[]) => {
                dispatch(setVisibleColumns(c));
              },
            }}
          />
        </>
      ) : (
        <Loading />
      )}
    </Box>
  );
};
