import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  FormControl,
  FormControlLabel,
  getEsgianTheme,
  Radio,
  RadioGroup,
  Table,
  Typography,
} from "@esgian/esgianui";

import { Loading } from "../../components/Loading";
import { isMatch } from "../../helper/isMatch";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getCountries,
  getRowsPerPage,
  getSearchText,
  getThemeMode,
  getUser,
} from "../../store/selector/common";
import {
  getFactoryContractList,
  getFactoryLoading,
} from "../../store/selector/factoryContract";
import {
  setRowsPerPage,
  setSearchObjectCoordinates,
  setSearchText,
} from "../../store/slice/common";
import { fetchFactoryContractList } from "../../store/slice/factoryContract";
import {
  CONTRACT_COLUMN,
  FactoriesTabEnum,
  THEME,
  ThemeModeEnum,
  UnifiedContract,
} from "../../types";

const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);

  return {
    container: {
      px: 2,
      maxWidth: "calc(100vw - 265px)",
      "& td": {
        color: `${
          themeMode === ThemeModeEnum.Dark
            ? theme.palette.common.white
            : theme.palette.common.black
        } !important`,
      },
    },
  };
};

export const FactoryContract = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const themeMode = useSelector(getThemeMode);
  const classes = useStyles(themeMode);
  const country = useSelector(getCountries);
  const rowsPerPage = useSelector(getRowsPerPage);
  const theme = useSelector(getThemeMode);
  const isLoading = useSelector(getFactoryLoading);
  const searchText = useSelector(getSearchText);
  const contractList = useSelector(getFactoryContractList);
  const user = useSelector(getUser);

  const [tab, setTab] = useState<FactoriesTabEnum>(
    FactoriesTabEnum.TurbineContracts,
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTab(event.target.value as FactoriesTabEnum);
  };

  const COLUMNS = useMemo(() => {
    return CONTRACT_COLUMN[tab];
  }, [tab]);

  const data = useMemo(() => {
    let list = contractList?.[tab] as UnifiedContract[];
    if (!list || !list?.length) return [];

    if (searchText?.length && list?.length) {
      list = list?.filter((item) =>
        isMatch(item, [searchText]),
      ) as UnifiedContract[];
    }
    return list;
  }, [contractList, tab, searchText]);

  useEffect(() => {
    if (id && country.length) {
      dispatch(fetchFactoryContractList(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, country]);

  useEffect(() => {
    return () => {
      dispatch(setSearchText(undefined));
      dispatch(setSearchObjectCoordinates(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasExportRight =
    Array.isArray(user?.profile?.permissionList) &&
    user?.profile?.permissionList?.includes("WIND_dataExports");

  return (
    <Box sx={classes.container}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FormControl component="fieldset" sx={{ px: 2 }} fullWidth={true}>
          <RadioGroup
            row={true}
            aria-labelledby="view-radio-buttons"
            name="view-radio-buttons"
            value={tab}
            onChange={handleChange}
          >
            <FormControlLabel
              value={FactoriesTabEnum.TurbineContracts}
              control={<Radio />}
              label="Turbine Contracts"
            />
            <FormControlLabel
              value={FactoriesTabEnum.FloatingContracts}
              control={<Radio />}
              label="Floating Contracts"
            />
            <FormControlLabel
              value={FactoriesTabEnum.FoundationContracts}
              control={<Radio />}
              label="Foundation Contracts"
            />
            <FormControlLabel
              value={FactoriesTabEnum.CableContracts}
              control={<Radio />}
              label="Cable Contracts"
            />
            <FormControlLabel
              value={FactoriesTabEnum.SubstationContracts}
              control={<Radio />}
              label="Substation Contracts"
            />
            <FormControlLabel
              value={FactoriesTabEnum.TowerContracts}
              control={<Radio />}
              label="Tower Contracts"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      {!isLoading ? (
        <>
          <Typography variant="body1" sx={{ py: 2 }}>
            {`Displaying ${data?.length} contracts`}
          </Typography>
          <Table
            app="wind"
            columnDefs={[]}
            columns={COLUMNS}
            data={data}
            elevation={0}
            mode={theme}
            options={{
              download: hasExportRight,
              search: false,
              downloadOptions: {
                filename: `installation_data.csv`,
              },
              expandableRows: false,
              filter: false,
              filterType: "multiselect",
              hideToolbarMargin: false,
              pagination: true,
              responsive: "vertical",
              rowsPerPage,
              onChangeRowsPerPage: (numberOfRows: number) => {
                dispatch(setRowsPerPage(numberOfRows));
              },
              rowsPerPageOptions: [25, 50, 100],
              selectableRows: "none",
              sortOrder: {
                direction: "asc",
                name: "technology",
              },
              showResponsive: true,
              tableBodyHeight: "calc(100vh - 290px)",
              toolbar: true,
              print: false,
              viewColumns: true,
              onViewColumnsChange: () => {},
            }}
          />
        </>
      ) : (
        <Loading />
      )}
    </Box>
  );
};
