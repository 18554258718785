import { FilterFactory } from "../../filterConfig/filterFactory";
import { sortValues } from "../../helper/fllter";
import {
  EquipmentContract,
  GenericType,
  RootState,
  VesselContract,
} from "../../types";
import {
  DATE_LABELS,
  DateConfig,
  DROPDOWN_LABELS,
  DropdownConfig,
  isDropdownConfig,
  isNumericConfig,
  NUMERIC_LABELS,
  NumericConfig,
} from "../../types/filters";

export const getEquipmentContractList = (state: RootState) => {
  return state.contract.equipmentContract;
};

export const getEquipmentContractChart = (state: RootState) => {
  return state.contract.equipmentChartData;
};

export const getEquipmentContractFilterOptions = (state: RootState) => {
  return state.contract.equipmentFilterOptions;
};

export const getEquipmentContractSelectedFilter = (state: RootState) => {
  return state.contract.equipmentSelectedFilter;
};

export const getVesselContractList = (state: RootState) => {
  return state.contract.vesselContract;
};

export const getVesselContractChart = (state: RootState) => {
  return state.contract.vesselChartData;
};

export const getVesselContractFilterOptions = (state: RootState) => {
  return state.contract.vesselFilterOptions;
};

export const getVesselContractSelectedFilter = (state: RootState) => {
  return state.contract.vesselSelectedFilter;
};

export const getVesselIsLoading = (state: RootState) => {
  return state.contract.vesselIsLoading;
};

export const getEquipmentIsLoading = (state: RootState) => {
  return state.contract.equipmentIsLoading;
};

export const getSortingInfo = (state: RootState) => {
  return state.contract.sortingInfo;
};

export const getFilterOptionsEquipment = (state: RootState, tab: string) => {
  if (!state.contract?.equipmentContract) return null;

  const commonFilters = {
    contractAwardDate: {
      inputLabelText: "Contract award date",
      format: "YYYY MM",
    },
    supplier: {
      inputLabelText: "Supplier",
      options: sortValues([
        ...new Set(
          state.contract.equipmentContract[tab as keyof EquipmentContract]
            ?.map((item) => item.supplier)
            .filter((value) => value != null && value !== "") || [],
        ),
      ]).map((value: string) => ({ id: value, value })), // Convert to { id, value }
    },
    windFarm: {
      inputLabelText: "Wind farm",
      options: sortValues([
        ...new Set(
          state.contract.equipmentContract[tab as keyof EquipmentContract]
            ?.map((item) => item.windFarm)
            .filter((value) => value != null && value !== "") || [],
        ),
      ]).map((value: string) => ({ id: value, value })), // Convert to { id, value }
    },
    country: {
      inputLabelText: "Country",
      options: sortValues([
        ...new Set(
          state.contract.equipmentContract[tab as keyof EquipmentContract]
            ?.map((item) => item.country)
            .filter((value) => value != null && value !== "") || [],
        ),
      ]).map((value: string) => ({ id: value, value })), // Convert to { id, value }
    },
    contractStatus: {
      inputLabelText: "Contract status",
      options: sortValues([
        ...new Set(
          state.contract.equipmentContract[tab as keyof EquipmentContract]
            ?.map((item) => item.contractStatus)
            .filter((value) => value != null && value !== "") || [],
        ),
      ]).map((value: string) => ({ id: value, value })), // Convert to { id, value }
    },
  };

  const tabSpecificFilters = {
    turbines: {
      turbineModel: {
        inputLabelText: "Turbine model",
        options: sortValues([
          ...new Set(
            state.contract.equipmentContract.turbines
              ?.map((item) => item.turbineModel)
              .filter((value) => value != null && value !== "") || [],
          ),
        ]).map((value: string) => ({ id: value, value })), // Convert to { id, value }
      },
    },
    foundations: {
      foundationType: {
        inputLabelText: "Foundation type",
        options: sortValues([
          ...new Set(
            state.contract.equipmentContract.foundations
              ?.map((item) => item.foundationType)
              .filter((value) => value != null && value !== "") || [],
          ),
        ]).map((value: string) => ({ id: value, value })), // Convert to { id, value }
      },
    },
    cables: {
      cableType: {
        inputLabelText: "Cable type",
        options: sortValues([
          ...new Set(
            state.contract.equipmentContract.cables
              ?.map((item) => item.cableType)
              .filter((value) => value != null && value !== "") || [],
          ),
        ]).map((value: string) => ({ id: value, value })), // Convert to { id, value }
      },
      voltageKV: {
        inputLabelText: "Voltage kV",
        options: [
          Math.min(
            ...new Set(
              state.contract.equipmentContract.cables
                ?.map((item) => item.voltageKV)
                .filter((value) => value != null) || [],
            ),
          ),
          Math.max(
            ...new Set(
              state.contract.equipmentContract.cables
                ?.map((item) => item.voltageKV)
                .filter((value) => value != null) || [],
            ),
          ),
        ],
      },
      outputCurrent: {
        inputLabelText: "Output current",
        options: sortValues([
          ...new Set(
            state.contract.equipmentContract.cables
              ?.map((item) => item.outputCurrent)
              .filter((value) => value != null && value !== "") || [],
          ),
        ]).map((value: string) => ({ id: value, value })), // Convert to { id, value }
      },
    },
    substations: {
      substation: {
        inputLabelText: "Substation",
        options: sortValues([
          ...new Set(
            state.contract.equipmentContract.substations
              ?.map((item) => item.substation)
              .filter((value) => value != null && value !== "") || [],
          ),
        ]).map((value: string) => ({ id: value, value })), // Convert to { id, value }
      },
      components: {
        inputLabelText: "Components",
        options: sortValues([
          ...new Set(
            state.contract.equipmentContract.substations
              ?.map((item) => item.components)
              .filter((value) => value != null && value !== "") || [],
          ),
        ]).map((value: string) => ({ id: value, value })), // Convert to { id, value }
      },
      foundationType: {
        inputLabelText: "Foundation type",
        options: sortValues([
          ...new Set(
            state.contract.equipmentContract.substations
              ?.map((item) => item.foundationType)
              .filter((value) => value != null && value !== "") || [],
          ),
        ]).map((value: string) => ({ id: value, value })), // Convert to { id, value }
      },
      outputCurrent: {
        inputLabelText: "Output current",
        options: sortValues([
          ...new Set(
            state.contract.equipmentContract.substations
              ?.map((item) => item.outputCurrent)
              .filter((value) => value != null && value !== "") || [],
          ),
        ]).map((value: string) => ({ id: value, value })), // Convert to { id, value }
      },
    },
    towers: {},
    "floating-equipment": {
      technologyType: {
        inputLabelText: "Technology type",
        options: sortValues([
          ...new Set(
            state.contract.equipmentContract["floating-equipment"]
              ?.map((item) => item.technologyType)
              .filter((value) => value != null && value !== "") || [],
          ),
        ]).map((value: string) => ({ id: value, value })), // Convert to { id, value }
      },
    },
  };

  const selectedFilters = {
    ...commonFilters,
    ...tabSpecificFilters[tab as keyof typeof tabSpecificFilters],
  };

  if (
    Object.values(selectedFilters).some((filter) => {
      if ("options" in filter) {
        return filter.options.size === 0; // Check if options Set is empty
      } else if ("format" in filter) {
        return !filter.format; // Check if format is missing
      }
      return false; // Default return if neither options nor format is present
    })
  ) {
    return null;
  }

  const result: GenericType = {};
  Object.entries(selectedFilters).forEach(([key, filter]) => {
    if ("options" in filter) {
      const options = filter.options;

      if (
        DROPDOWN_LABELS.includes(filter.inputLabelText) &&
        isDropdownConfig(options)
      ) {
        // Handle DropdownConfig
        result[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as DropdownConfig["inputLabelText"],
          options: sortValues(
            options.map((option) => ({ id: option.id, value: option.value })),
          ),
        });
      } else if (
        NUMERIC_LABELS.includes(filter.inputLabelText) &&
        isNumericConfig(options)
      ) {
        // Handle NumericConfig
        result[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as NumericConfig["inputLabelText"],
          options: options as [number, number], // Ensure it's treated as a NumericConfig
        });
      }
    } else if ("format" in filter) {
      if (DATE_LABELS.includes(filter.inputLabelText)) {
        const dateFilter = filter as DateConfig;
        result[key] = FilterFactory.createFilter({
          inputLabelText:
            dateFilter.inputLabelText as DateConfig["inputLabelText"],
          format: dateFilter.format,
        });
      }
    } else {
      result[key] = null;
    }
  });
  return result;
};

export const getFilterOptionsVessel = (state: RootState, tab: string) => {
  if (!state.contract?.vesselContract) return null;

  const commonFilters = {
    contractAwardDate: {
      inputLabelText: "Contract award date",
      format: "YYYY MM",
    },
    startDate: {
      inputLabelText: "Start date",
      format: "YYYY-MM-DD",
    },
    endDate: {
      inputLabelText: "End date",
      format: "YYYY-MM-DD",
    },
    vesselName: {
      inputLabelText: "Vessel name",
      options: sortValues([
        ...new Set(
          state.contract.vesselContract[tab as keyof VesselContract]
            ?.map((item) => item.vesselName)
            .filter((value) => value != null && value !== "") || [],
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    vesselOwner: {
      inputLabelText: "Vessel owner",
      options: sortValues([
        ...new Set(
          state.contract.vesselContract[tab as keyof VesselContract]
            ?.map((item) => item.vesselOwner)
            .filter((value) => value != null && value !== "") || [],
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    windFarm: {
      inputLabelText: "Wind farm",
      options: sortValues([
        ...new Set(
          state.contract.vesselContract[tab as keyof VesselContract]
            ?.map((item) => item.windFarm)
            .filter((value) => value != null && value !== "") || [],
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
    country: {
      inputLabelText: "Country",
      options: sortValues([
        ...new Set(
          state.contract.vesselContract[tab as keyof VesselContract]
            ?.map((item) => item.country)
            .filter((value) => value != null && value !== "") || [],
        ),
      ]).map((value: string) => ({ id: value, value })),
    },
  };

  const tabSpecificFilters = {
    "all-vessels": {
      turbineModel: {
        inputLabelText: "Turbine model",
        options: sortValues([
          ...new Set(
            state.contract.vesselContract["all-vessels"]
              ?.map((item) => item.turbineModel)
              .filter((value) => value != null && value !== "") || [],
          ),
        ]).map((value: string) => ({ id: value, value })),
      },
      foundationType: {
        inputLabelText: "Foundation type",
        options: sortValues([
          ...new Set(
            state.contract.vesselContract["all-vessels"]
              ?.map((item) => item.foundationType)
              .filter((value) => value != null && value !== "") || [],
          ),
        ]).map((value: string) => ({ id: value, value })),
      },
      equipmentType: {
        inputLabelText: "Contracted equipment type",
        options: sortValues([
          ...new Set(
            state.contract.vesselContract["all-vessels"]
              ?.map((item) => item.equipmentType)
              .filter((value) => value != null && value !== "") || [],
          ),
        ]).map((value: string) => ({ id: value, value })),
      },
      contractStatus: {
        inputLabelText: "Contract status",
        options: sortValues([
          ...new Set(
            state.contract.vesselContract["all-vessels"]
              ?.map((item) => item.contractStatus)
              .filter((value) => value != null && value !== "") || [],
          ),
        ]).map((value: string) => ({ id: value, value })),
      },
    },
    "cable-lay": {
      cableType: {
        inputLabelText: "Cable type",
        options: sortValues([
          ...new Set(
            state.contract.vesselContract["cable-lay"]
              ?.map((item) => item.equipmentType)
              .filter((value) => value != null && value !== "") || [],
          ),
        ]).map((value: string) => ({ id: value, value })),
      },
      contractStatus: {
        inputLabelText: "Contract status",
        options: sortValues([
          ...new Set(
            state.contract.vesselContract["cable-lay"]
              ?.map((item) => item.contractStatus)
              .filter((value) => value != null && value !== "") || [],
          ),
        ]).map((value: string) => ({ id: value, value })),
      },
    },
    "floating-installation": {
      floatingTechnology: {
        inputLabelText: "Floating technology",
        options: sortValues([
          ...new Set(
            state.contract.vesselContract["floating-installation"]
              ?.map((item) => item.floatingTechnology)
              .filter((value) => value != null && value !== "") || [],
          ),
        ]).map((value: string) => ({ id: value, value })),
      },
    },
    installation: {
      turbineModel: {
        inputLabelText: "Turbine model",
        options: sortValues([
          ...new Set(
            state.contract.vesselContract.installation
              ?.map((item) => item.turbineModel)
              .filter((value) => value != null && value !== "") || [],
          ),
        ]).map((value: string) => ({ id: value, value })),
      },
      foundationType: {
        inputLabelText: "Foundation type",
        options: sortValues([
          ...new Set(
            state.contract.vesselContract.installation
              ?.map((item) => item.foundationType)
              .filter((value) => value != null && value !== "") || [],
          ),
        ]).map((value: string) => ({ id: value, value })),
      },
      contractStatus: {
        inputLabelText: "Contract status",
        options: sortValues([
          ...new Set(
            state.contract.vesselContract["cable-lay"]
              ?.map((item) => item.contractStatus)
              .filter((value) => value != null && value !== "") || [],
          ),
        ]).map((value: string) => ({ id: value, value })),
      },
    },
    maintenance: {
      turbineModel: {
        inputLabelText: "Turbine model",
        options: sortValues([
          ...new Set(
            state.contract.vesselContract.maintenance
              ?.map((item) => item.turbineModel)
              .filter((value) => value != null && value !== "") || [],
          ),
        ]).map((value: string) => ({ id: value, value })),
      },
      foundationType: {
        inputLabelText: "Foundation type",
        options: sortValues([
          ...new Set(
            state.contract.vesselContract.maintenance
              ?.map((item) => item.foundationType)
              .filter((value) => value != null && value !== "") || [],
          ),
        ]).map((value: string) => ({ id: value, value })),
      },
    },
  };

  const selectedFilters = {
    ...commonFilters,
    ...tabSpecificFilters[tab as keyof typeof tabSpecificFilters],
  };

  if (
    Object.values(selectedFilters).some((filter) => {
      if ("options" in filter) {
        return filter.options.size === 0; // Check if options Set is empty
      } else if ("format" in filter) {
        return !filter.format; // Check if format is missing
      }
      return false; // Default return if neither options nor format is present
    })
  ) {
    return null;
  }

  const result: GenericType = {};
  Object.entries(selectedFilters).forEach(([key, filter]) => {
    if ("options" in filter) {
      const options = filter.options;

      if (
        DROPDOWN_LABELS.includes(filter.inputLabelText) &&
        isDropdownConfig(options)
      ) {
        // Handle DropdownConfig
        result[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as DropdownConfig["inputLabelText"],
          options: sortValues(
            options.map((option) => ({ id: option.id, value: option.value })),
          ),
        });
      } else if (
        NUMERIC_LABELS.includes(filter.inputLabelText) &&
        isNumericConfig(options)
      ) {
        // Handle NumericConfig
        result[key] = FilterFactory.createFilter({
          inputLabelText:
            filter.inputLabelText as NumericConfig["inputLabelText"],
          options: options as [number, number], // Ensure it's treated as a NumericConfig
        });
      }
    } else if ("format" in filter) {
      if (DATE_LABELS.includes(filter.inputLabelText)) {
        const dateFilter = filter as DateConfig;
        result[key] = FilterFactory.createFilter({
          inputLabelText:
            dateFilter.inputLabelText as DateConfig["inputLabelText"],
          format: dateFilter.format,
        });
      }
    } else {
      result[key] = null;
    }
  });
  return result;
};
