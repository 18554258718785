import { FC, useEffect, useState } from "react";
import { Box } from "@esgian/esgianui";
import csvDownload from "json-to-csv-export";
import mapboxgl, { Map as MapType } from "mapbox-gl";
import moment from "moment";

import {
  AddInfoButton,
  CENTER,
  CreateMap,
  // fetchStateName,
  searchZoom,
  THRESHOLD_ZOOM,
  transformAndAddOffsets,
  ZOOM,
} from "../../helper/map";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getCountries,
  getSearchObject,
  getThemeMode,
  getUser,
  getUserLocationCoordinates,
} from "../../store/selector/common";
import { getTargetList } from "../../store/selector/target";
import { getInfoModal } from "../../store/selector/windLeasingAndProject";
import { fetchTargetList } from "../../store/slice/target";
import { setInfoModal } from "../../store/slice/windLeasingAndProject";
import {
  CoordinatesObj,
  GenericType,
  MapLayer,
  SpiderTarget,
  Target,
} from "../../types";
import { TargetInfoModel } from "../Modal/TargetInfoModal";
import { TimeLineSlider } from "../TimeLineSlider";

import { Circle } from "./helper/CircleLayer";
import { SpiderCircleLayer } from "./helper/SpiderCircleLayer";
import { ExportBtn, generatePopupHTML, useStyles } from "./helper";

const { REACT_APP_MAP_TOKEN } = process.env;

type Prop = {
  controller?: boolean;
  zoom?: number;
  center?: [number, number] | CoordinatesObj;
  move?: boolean;
};

export const TargetMap: FC<Prop> = ({
  controller = true,
  zoom = ZOOM,
  center = CENTER,
  move = true,
}) => {
  const dispatch = useDispatch();
  const circle = new Circle();
  const spiderCircleLayer = new SpiderCircleLayer();
  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: true,
    anchor: "left",
    offset: 28,
  });

  const themeMode = useSelector(getThemeMode);
  const userLocationCoordinates = useSelector(getUserLocationCoordinates);
  const classes = useStyles(themeMode);
  const targetList = useSelector(getTargetList);
  const countries = useSelector(getCountries);
  const searchObject = useSelector(getSearchObject);
  const isInfoModalOpen = useSelector(getInfoModal);
  const user = useSelector(getUser);
  const [target, setTarget] = useState<Target[]>([]);
  const [spiderTarget, setSpiderTarget] = useState<SpiderTarget[]>([]);

  const [selectedYear, setSelectedYear] = useState<number>(
    Number(moment().format("YYYY")),
  );

  const [mapInstance, setMapInstance] = useState<MapType | null>(null);
  const min = moment().format("YYYY");
  const max = moment().add(26, "y").format("YYYY");

  const exportCsv = () => {
    const dataToConvert = {
      data:
        targetList?.map((item) => ({
          country: item.country,
          otherRegion: item.landRegion,
          year: item.year,
          capacity: item.capacity,
          announcedDate:
            item.announcedDate !== "0001-01-01T00:00:00"
              ? moment(item.announcedDate).format("YYYY-MM-DD")
              : "",
          type: item.type,
          "Centroid Coordinates": JSON.stringify(item.centroidCoordinates),
        })) || [],
      filename: "ip_addresses_report",
      delimiter: ",",
      headers: [
        "Country",
        "Other region",
        "Year",
        "Capacity",
        "Announced Date",
        "Type",
        "Centroid Coordinates",
      ],
    };
    return csvDownload(dataToConvert);
  };

  const hasExportRight =
    Array.isArray(user?.profile?.permissionList) &&
    user?.profile?.permissionList?.includes("WIND_dataExports");

  useEffect(() => {
    mapboxgl.accessToken = REACT_APP_MAP_TOKEN ?? "";
    const map = CreateMap(zoom, center, move, themeMode);
    map.on("load", () => {
      setMapInstance(map);
      circle.init(map);
      spiderCircleLayer.init(map, themeMode);
      if (controller) {
        map.addControl(new mapboxgl.NavigationControl(), "top-right");
        if (hasExportRight) {
          ExportBtn(map, classes.filterButton, exportCsv);
        }
      }
      AddInfoButton(map, classes.filterButton, () =>
        dispatch(setInfoModal(true)),
      );
      if (userLocationCoordinates) {
        map.jumpTo({
          center: userLocationCoordinates,
          zoom: THRESHOLD_ZOOM,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeMode]);

  useEffect(() => {
    if (countries.length) {
      dispatch(fetchTargetList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);

  useEffect(() => {
    if (mapInstance) {
      circle.update(mapInstance, target, selectedYear);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target, mapInstance, selectedYear]);

  useEffect(() => {
    if (mapInstance) {
      spiderCircleLayer.update(mapInstance, spiderTarget, selectedYear);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spiderTarget, mapInstance, selectedYear]);

  useEffect(() => {
    if (mapInstance) {
      mapInstance.on("click", MapLayer.Circle, async (e: GenericType) => {
        const firstFeature = e.features[0];
        const properties = firstFeature.properties;
        const html = generatePopupHTML(properties);
        popup
          .setLngLat(firstFeature.geometry.coordinates)
          .setHTML(html)
          .addTo(mapInstance);
      });

      mapInstance.on("click", MapLayer.SpiderCircle, async (e: GenericType) => {
        const firstFeature = e.features[0];
        const html = generatePopupHTML(firstFeature.properties);
        popup
          .setLngLat(firstFeature.geometry.coordinates)
          .setHTML(html)
          .addTo(mapInstance);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapInstance]);

  useEffect(() => {
    if (mapInstance && searchObject && move) {
      if (searchObject) {
        searchZoom(mapInstance, searchObject);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLocationCoordinates, searchObject, move]);

  useEffect(() => {
    const selectedDate = moment(`${selectedYear}-12-31`).endOf("day");
    const coordinatesCount = new Map<string, Target[]>();
    targetList?.forEach((item) => {
      if (
        item.centroidCoordinates &&
        moment(`${item.year}-01-01`).isSameOrBefore(selectedDate)
      ) {
        const coordKey = `${item.centroidCoordinates.lat},${item.centroidCoordinates.lon}`;

        if (!coordinatesCount.has(coordKey)) {
          coordinatesCount.set(coordKey, []);
        }
        coordinatesCount.get(coordKey)?.push(item);
      }
    });

    const uniqueItems: Target[] = [];
    const duplicateItems: Target[][] = [];

    coordinatesCount.forEach((items) => {
      if (items.length === 1) {
        uniqueItems.push(items[0]);
      } else if (items.length > 1) {
        duplicateItems.push(items);
      }
    });
    const radiusItems = transformAndAddOffsets(duplicateItems);
    setSpiderTarget(radiusItems);
    setTarget(uniqueItems || []);
  }, [selectedYear, targetList]);

  return (
    <Box sx={{ height: "100%" }}>
      <Box id="map" sx={classes.map} />
      <TimeLineSlider
        min={Number(min)}
        max={Number(max)}
        onChange={(e) => setSelectedYear(e)}
      />
      <TargetInfoModel
        open={isInfoModalOpen}
        handleClose={() => dispatch(setInfoModal(false))}
      />
    </Box>
  );
};
