import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Route, Routes, useLocation } from "react-router-dom";
import { Box, EsgianTheme } from "@esgian/esgianui";

// import { api } from "./api/api";
import { logEvent } from "./api/loggingService/loggingAliasMethods";
import errorGif from "./assets/error.gif";
import { Layout } from "./components/Layout";
import { useDispatch } from "./hooks/use-dispatch";
import { useSelector } from "./hooks/use-selector";
import { getGlobalError, getThemeMode } from "./store/selector/common";
import { fetchLookup, setUserLocationCoordinates } from "./store/slice/common";
import {
  ActivityVisualisation,
  Analytics,
  CableLay,
  Contract,
  Enquiry,
  Factory,
  FactoryContract,
  Faq,
  FloatingTechnology,
  Installation,
  InstallationVesselAvailability,
  LeasingRound,
  NewFeedItem,
  NewsFeed,
  PowerOutput,
  PowerOutputProfile,
  PPAContract,
  SupplyDemand,
  Target,
  TurbineModel,
  WindLeasingAndProject,
} from "./pages";
import { ROUTES_CONFIG } from "./types";

import "react-toastify/dist/ReactToastify.css";
/* eslint-disable react/require-default-props */

const useStyles = () => {
  return {
    errorContainer: {
      width: "100vw",
      height: "100vh",
      backgroundImage: `url(${errorGif})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
    content: {
      width: "100%",
      height: "calc(100vh - 72px)",
    },
  };
};

function App() {
  const classes = useStyles();
  const location = useLocation();
  const [startTime, setStartTime] = useState(Date.now());
  const dispatch = useDispatch();
  const themeMode = useSelector(getThemeMode);
  const globalError = useSelector(getGlobalError);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });

    logEvent("Page View Event Start", undefined, {
      path: location.pathname,
    });

    setStartTime(Date.now());

    return () => {
      const endTime = Date.now();
      const timeSpent = (endTime - startTime) / 1000;

      ReactGA.event({
        category: "Time on Page",
        action: "Page View Duration",
        label: location.pathname,
        value: timeSpent,
      });

      logEvent("Page View Event End", undefined, {
        category: "Time on Page",
        action: "Page View Duration",
        label: location.pathname,
        value: timeSpent,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    dispatch(fetchLookup(["countries"]));
    navigator?.geolocation?.getCurrentPosition((position) => {
      dispatch(
        setUserLocationCoordinates([
          position?.coords?.longitude,
          position?.coords?.latitude,
        ]),
      );
    });
  }, [dispatch]);

  return (
    <EsgianTheme app="wind" mode={themeMode}>
      {globalError ? (
        <Box sx={classes.errorContainer} />
      ) : (
        <>
          <Layout>
            <Routes>
              <Route
                path={ROUTES_CONFIG.WindLeasingAndProject.url}
                element={<WindLeasingAndProject />}
              />
              <Route
                path={ROUTES_CONFIG.LeaseRoundTimeLineId.url}
                element={<LeasingRound />}
              />
              <Route
                path={ROUTES_CONFIG.LeaseRoundTimeLine.url}
                element={<LeasingRound />}
              />
              <Route path={ROUTES_CONFIG.Factory.url} element={<Factory />} />
              <Route
                path={ROUTES_CONFIG.TurbineModel.url}
                element={<TurbineModel />}
              />
              <Route
                path={ROUTES_CONFIG.FloatingTechnology.url}
                element={<FloatingTechnology />}
              />
              <Route
                path={ROUTES_CONFIG.FactoryContract.url}
                element={<FactoryContract />}
              />
              <Route
                path={ROUTES_CONFIG.Installation.url}
                element={<Installation />}
              />
              <Route path={ROUTES_CONFIG.CableLay.url} element={<CableLay />} />
              <Route
                path={ROUTES_CONFIG.SupplyDemand.url}
                element={<SupplyDemand />}
              />
              <Route path={ROUTES_CONFIG.Target.url} element={<Target />} />
              <Route path={ROUTES_CONFIG.Contract.url} element={<Contract />} />
              <Route path={ROUTES_CONFIG.PPA.url} element={<PPAContract />} />
              <Route
                path={ROUTES_CONFIG.NewsFeedItem.url}
                element={<NewFeedItem />}
              />
              <Route path={ROUTES_CONFIG.NewsFeed.url} element={<NewsFeed />} />
              <Route
                path={ROUTES_CONFIG.InstallationVesselAvailability.url}
                element={<InstallationVesselAvailability />}
              />
              <Route
                path={ROUTES_CONFIG.Analytics.url}
                element={<Analytics />}
              />
              <Route
                path={ROUTES_CONFIG.ActivityVisualisation.url}
                element={<ActivityVisualisation />}
              />
              <Route
                path={ROUTES_CONFIG.PowerOutput.url}
                element={<PowerOutput />}
              />
              <Route
                path={ROUTES_CONFIG.PowerOutputProfile.url}
                element={<PowerOutputProfile />}
              />
              <Route path={ROUTES_CONFIG.faq.url} element={<Faq />} />
              <Route path={ROUTES_CONFIG.Enquiry.url} element={<Enquiry />} />
            </Routes>
          </Layout>
        </>
      )}
    </EsgianTheme>
  );
}

export default App;
