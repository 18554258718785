/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

import { api } from "../../api/api";
import { Factory, FactoryInfo, GenericType, SortingInfo } from "../../types";

type State = {
  isLoading: boolean;
  factoryList: Factory[] | null;
  factoryInfoList: FactoryInfo[] | null;
  selectedFilter: GenericType;
  equipmentTypes: GenericType[];
  sortingInfo: SortingInfo;
};

const initialState: State = {
  isLoading: false,
  factoryList: [],
  factoryInfoList: [],
  selectedFilter: {},
  equipmentTypes: [],
  sortingInfo: {
    direction: "asc",
    name: "name",
  },
};

export const fetchFactoryDataMap = createAsyncThunk("factory/map", async () => {
  try {
    const { data } = await api.factory.getFactory({});
    return data.filter(
      (item: Factory) => item.coordinates.lat && item.coordinates.lon,
    );
  } catch (err) {
    return [];
  }
});

export const fetchFactoryDataList = createAsyncThunk(
  "factoryInfo/list",
  async () => {
    const { data } = await api.windFactoryContract.getFactoryInfo({});

    const equipmentTypesMap = new Map<string, GenericType>();

    const factoryIds: string[] = [];
    data.forEach((factory: GenericType) => {
      factoryIds.push(factory?.factoryInformation?.countryId);
    });
    const { data: allCountries } = await api.countries.getAllCountries({
      ids: factoryIds,
    });

    const list = data.map((factory: GenericType) => {
      const factoryOwners = factory?.factoryInformation?.factoryOwners
        ?.map((item: GenericType) => item?.name)
        .join(", ");
      if (factory.factoryInformation?.equipmentTypes) {
        factory.factoryInformation.equipmentTypes.forEach(
          (type: GenericType) => {
            if (!equipmentTypesMap.has(type.id)) {
              equipmentTypesMap.set(type.id, type);
            }
          },
        );
      }
      return {
        ...factory.factoryInformation,
        factoryStatus: factory.factoryInformation.status.status,
        factoryFunctions: factory?.factoryInformation?.factoryFunctions
          ?.map((f: GenericType) => f.function)
          .join(", "),
        country: allCountries.find(
          (item: GenericType) =>
            item.id === factory?.factoryInformation?.countryId,
        )?.name,
        operationDate: factory?.factoryInformation?.operationDate
          ? moment(moment(factory?.factoryInformation?.operationDate)).format(
              "DD-MM-YYYY",
            )
          : null,
        closureDate: factory?.factoryInformation?.closureDate
          ? moment(moment(factory?.factoryInformation?.closureDate)).format(
              "DD-MM-YYYY",
            )
          : null,
        factoryOwners,
      };
    });
    return { list, equipmentTypes: Array.from(equipmentTypesMap.values()) };
  },
);

const factorySlice = createSlice({
  name: "factory",
  initialState,
  reducers: {
    setSelectedFilter: (state, action: PayloadAction<GenericType>) => {
      state.selectedFilter = action.payload;
    },
    setSortingInfo: (state, action: PayloadAction<SortingInfo>) => {
      state.sortingInfo = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFactoryDataMap.pending, function (state) {
        state.isLoading = true;
        state.factoryList = [];
      })
      .addCase(fetchFactoryDataMap.rejected, (state) => {
        state.isLoading = false;
        state.factoryList = [];
      })
      .addCase(fetchFactoryDataMap.fulfilled, (state, action) => {
        state.factoryList = action.payload;
        state.isLoading = false;
      });
    builder
      .addCase(fetchFactoryDataList.pending, function (state) {
        state.isLoading = true;
        state.factoryInfoList = [];
      })
      .addCase(fetchFactoryDataList.rejected, (state) => {
        state.isLoading = false;
        state.factoryInfoList = [];
      })
      .addCase(fetchFactoryDataList.fulfilled, (state, action) => {
        state.factoryInfoList = action.payload.list;
        if (!state.equipmentTypes?.length) {
          state.equipmentTypes = action.payload.equipmentTypes;
        }
        state.isLoading = false;
      });
  },
});

export const { setSelectedFilter, setSortingInfo } = factorySlice.actions;
export const factory = factorySlice.reducer;
