import moment from "moment";

export const getSourceText = (
  sourceName: string,
  powerGenerationDate?: string,
  countryName?: string,
): string => {
  if (sourceName === "EEX") {
    return "Source: EEX Transparency Platform";
  }

  if (sourceName === "EIA") {
    return "Source: U.S. Energy Information Administration";
  }

  if (sourceName === "Energistyrelsen") {
    return "Source: Energistyrelsen";
  }

  if (sourceName === "Taipower") {
    return "Source: Taiwan Power Company, Open Government Data License, version 1.0";
  }

  if (
    powerGenerationDate &&
    moment(powerGenerationDate, "YYYY-MM-DD").isBefore(
      moment("2019-02-01", "YYYY-MM-DD"),
    ) &&
    (countryName === "UK" || countryName === "United Kingdom")
  ) {
    return `Source: ENTSO-E Transparency Platform, contains BMRS data © Elexon Limited copyright and database right ${moment().year()}`;
  }

  if (
    powerGenerationDate &&
    moment(powerGenerationDate, "YYYY-MM-DD").isSameOrAfter(
      moment("2019-02-01", "YYYY-MM-DD"),
    ) &&
    (countryName === "UK" || countryName === "United Kingdom")
  ) {
    return `Source: Contains BMRS data © Elexon Limited copyright and database right ${moment().year()}`;
  }

  if (sourceName === "ENTSOE") {
    return "Source: ENTSO-E Transparency Platform";
  }

  return "";
};
