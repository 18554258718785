import axios, { AxiosRequestConfig } from "axios";

import { GenericType } from "../types";

import { Api, RequestConfig, Transport, TransportConfig } from "./types";

export function initApi<T>(
  apiDeclaration: GenericType,
  extender: AxiosRequestConfig = {},
  transport: Transport = axios,
) {
  const api: GenericType = {} as Api<T>;

  Object.keys(apiDeclaration).forEach((key) => {
    api[key] = async function (
      params: GenericType,
      transportConfig?: TransportConfig,
    ) {
      let requestConfig: RequestConfig = apiDeclaration[key](params);
      const requestHeaders = requestConfig.headers;
      const apiHeaders = extender.headers;

      if (params?.cancelToken) {
        requestConfig.cancelToken = params.cancelToken.token;
      }

      requestConfig = Object.assign(
        {},
        extender,
        requestConfig,
        {
          headers: {
            ...apiHeaders,
            ...requestHeaders,
            EsgianApiKey: process.env.REACT_APP_ESGIAN_API_KEY,
          },
        },
        transportConfig,
      );
      return transport(requestConfig);
    };
  });

  return api;
}
