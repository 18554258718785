import { FC, useCallback, useMemo } from "react";
import {
  Box,
  CircularProgress,
  DeleteOutlineIcon,
  FormControl,
  IconButton,
  Stack,
  Table,
  Typography,
} from "@esgian/esgianui";

import { getSourceText } from "../../helper/powerGenSoureRef";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import { usePowerGenQueries } from "../../hooks/useQueries/usePowerGenQueries";
import { getThemeMode, getUser } from "../../store/selector/common";
import {
  getPowerOutputCompareWindProjects,
  getPowerOutputWindProject,
} from "../../store/selector/powerOutput";
import {
  deleteCompareWindProjects,
  setCompareWindProjects,
} from "../../store/slice/powerOutput";
import { GenericType, SelectedFilterItem } from "../../types";
import { StyledAutocomplete } from "../StyledAutocomplete";

type Prop = {
  sx?: GenericType;
  selectedTab?: string;
};

export const PowerOutputCompareProject: FC<Prop> = ({ sx, selectedTab }) => {
  const dispatch = useDispatch();

  const { powerDataFullProjectQuery, powerDataQuery } = usePowerGenQueries();
  const selectedProject = useSelector(getPowerOutputWindProject);
  const selectedCompareItems = useSelector(getPowerOutputCompareWindProjects);
  const themeMode = useSelector(getThemeMode);
  const user = useSelector(getUser);
  const isCapacity = selectedTab === "capacity";
  const tableData = useMemo(() => {
    if (!selectedProject) return [];
    if (!powerDataFullProjectQuery.data) return [];
    const ids = [
      selectedProject.id,
      ...(selectedCompareItems ? selectedCompareItems.map(({ id }) => id) : []),
    ];

    const data: GenericType[] = [];
    const queryData = powerDataFullProjectQuery.data as Record<
      string,
      GenericType
    >;
    ids.forEach((projectId) => {
      const project = queryData[projectId];
      if (!project) return;
      const {
        firstRecordTime,
        accumulatedData: { capacityFactor, generationOutput },
        countryName,
      } = project;
      const accum = isCapacity ? capacityFactor : generationOutput;
      if (
        project.sourceName === "Energistyrelsen" ||
        project.sourceName === "EIA"
      ) {
        Object.keys(accum).forEach((key: string) => {
          accum[key] = {
            value: 0,
            count: 0,
            min: 0,
            max: 0,
          };
        });
      }
      data.push({
        name: project.name,
        id: project.id,
        sourceName: project.sourceName,
        firstRecordTime: firstRecordTime,
        capacityMw: project.capacityMw,
        countryName: countryName,
        ...accum,
      });
    });
    return data;
  }, [
    isCapacity,
    powerDataFullProjectQuery.data,
    selectedCompareItems,
    selectedProject,
  ]);

  const projectOptions = useMemo(() => {
    if (!powerDataQuery.data) return [];
    if (!selectedProject) return [];
    const ids = [
      ...(selectedCompareItems ? selectedCompareItems.map(({ id }) => id) : []),
      selectedProject.id,
    ];

    return Object.values(powerDataQuery.data)
      .filter((item) => !ids.includes(item.id))
      .map((value) => ({
        sourceName: value.sourceName,
        name: value.name,
        id: value.id,
      }));
  }, [powerDataQuery, selectedProject, selectedCompareItems]);

  const renderTimeColumn = useCallback(
    (obj: GenericType): string => {
      const value = obj.value;
      const count = obj.count;
      if (count) {
        return `${Number(value).toFixed(2)} ${isCapacity ? " %" : ""}`;
      }
      return "-";
    },
    [isCapacity],
  );

  const sortCompare = useCallback((order: "asc" | "desc") => {
    return (obj1: GenericType, obj2: GenericType) => {
      return (obj1.data.value - obj2.data.value) * (order === "asc" ? 1 : -1);
    };
  }, []);

  const COLUMNS = useMemo(() => {
    return [
      {
        label: "#",
        name: "id",
        options: {
          filter: false,
          sort: false,
          display: false,
        },
      },
      {
        label: "#",
        name: "firstRecordTime",
        options: {
          filter: false,
          sort: false,
          display: false,
        },
      },
      {
        label: "Name",
        name: "name",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value: string, tableMeta: GenericType) => {
            const isPrim = tableMeta.rowData[0] == selectedProject?.id;
            return (
              <Stack direction="row" spacing={1} sx={{ alignItems: "left" }}>
                <IconButton
                  disabled={isPrim}
                  sx={{ p: 0 }}
                  aria-label="delete"
                  onClick={() => {
                    dispatch(deleteCompareWindProjects(tableMeta.rowData[0]));
                  }}
                >
                  <DeleteOutlineIcon
                    color={isPrim ? "text.disabled" : undefined}
                    sx={{ height: "16px" }}
                    fontSize="small"
                  />
                </IconButton>
                <Typography
                  sx={{ width: "100%" }}
                  variant="caption"
                  color="primary"
                >
                  {value}
                </Typography>
              </Stack>
            );
          },
        },
      },
      {
        label: "Current installed capacity (MW)",
        name: "capacityMw",
      },
      {
        label: "1 week",
        name: `oneWeeks`,
        options: {
          customBodyRender: renderTimeColumn,
          sortCompare: sortCompare,
        },
      },
      {
        label: "1 month",
        name: `oneMonths`,
        options: {
          customBodyRender: renderTimeColumn,
          sortCompare: sortCompare,
        },
      },
      {
        label: "3 months",
        name: `threeMonths`,
        options: {
          customBodyRender: renderTimeColumn,
          sortCompare: sortCompare,
        },
      },
      {
        label: "6 months",
        name: `sixMonths`,
        options: {
          customBodyRender: renderTimeColumn,
          sortCompare: sortCompare,
        },
      },
      {
        label: "YTD",
        name: `ytd`,
        options: {
          customBodyRender: renderTimeColumn,
          sortCompare: sortCompare,
        },
      },
      {
        label: "1 year",
        name: `oneYears`,
        options: {
          customBodyRender: renderTimeColumn,
          sortCompare: sortCompare,
        },
      },
      {
        label: "3 years",
        name: `threeYears`,
        options: {
          customBodyRender: renderTimeColumn,
          sortCompare: sortCompare,
        },
      },
      {
        label: "5 years",
        name: `fiveYears`,
        options: {
          customBodyRender: renderTimeColumn,
          sortCompare: sortCompare,
        },
      },
      {
        label: "Max",
        name: `max`,
        options: {
          customBodyRender: renderTimeColumn,
          sortCompare: sortCompare,
        },
      },
    ];
  }, [renderTimeColumn, sortCompare, selectedProject?.id, dispatch]);

  let title = "";
  if (selectedTab === "capacity") {
    title = "Capacity factor overview";
  }
  if (selectedTab === "output") {
    title = "Power generation overview (GWh)";
  }

  const sourceDisclaimer = getSourceText(
    tableData?.[0]?.sourceName,
    tableData?.[0]?.firstRecordTime,
    tableData?.[0]?.countryName,
  );

  const hasExportRight =
    Array.isArray(user?.profile?.permissionList) &&
    user?.profile?.permissionList?.includes("WIND_dataExports");

  return (
    <Box
      sx={{
        background: ({ palette }: GenericType) => palette.background.paper,
        p: 2,
        height: "100%",
        borderRadius: 4,
        ...sx,
      }}
    >
      <Stack
        sx={{ width: "100%" }}
        direction="row"
        justifyContent="space-between"
      >
        <Typography variant="h6">{title}</Typography>
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography>Compare to </Typography>
          <FormControl
            sx={{
              width: 340,
              "& .MuiSelect-select": {
                height: "38px !important",
                lineHeight: "36px",
              },
              mr: 2,
            }}
          >
            <StyledAutocomplete
              loading={projectOptions.length === 0}
              disableClearable={true}
              disableCloseOnSelect={false}
              options={projectOptions}
              selectedItem={[]}
              setSelectedItem={(value) => {
                const tempArray = Array.isArray(value)
                  ? [...selectedCompareItems, ...value]
                  : [...selectedCompareItems, value];
                dispatch(
                  setCompareWindProjects(tempArray as SelectedFilterItem[]),
                );
              }}
              labelKey="name"
              multipleSelection={true}
              size="medium"
              placeholder="Wind farm"
              maxHeight="44vh"
              disabled={selectedCompareItems.length >= 6}
            />
          </FormControl>
        </Stack>
      </Stack>
      {powerDataFullProjectQuery.isFetched ? (
        <Stack direction="column" spacing={2}>
          <Box sx={{ overflowX: "auto" }}>
            <Table
              app="wind"
              columnDefs={[]}
              columns={COLUMNS}
              data={tableData}
              elevation={0}
              mode={themeMode}
              options={{
                download: hasExportRight,
                search: false,
                downloadOptions: {
                  filename: `turbine_models_data.csv`,
                },
                expandableRows: false,
                filter: false,
                hideToolbarMargin: false,
                pagination: false,
                responsive: "standard",
                selectableRows: "none",
                showResponsive: false,
                toolbar: true,
                print: false,
                viewColumns: false,
              }}
            />
          </Box>
          <Typography variant="subtitle2">{sourceDisclaimer}</Typography>
        </Stack>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};
