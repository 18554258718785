import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

import { api } from "../../api/api";
import {
  convertToDesiredArrayFormat,
  findContractStatus,
} from "../../helper/chart";
import { objectFillerDates } from "../../helper/datesCalculations";
import { generateDateFilter, generateOptions } from "../../helper/fllter";
import {
  AllVessels,
  CableLay,
  Country,
  EquipmentContract,
  FloatingInstallation,
  GenericType,
  IdTypePair,
  InstallationContract,
  RootState,
  SortingInfo,
  VesselContract,
  VesselContractChart,
} from "../../types";

type State = {
  equipmentIsLoading: boolean;
  vesselIsLoading: boolean;
  equipmentContract: EquipmentContract | null;
  vesselContract: VesselContract | null;
  equipmentChartData: {
    turbines: GenericType[];
    foundations: GenericType[];
    cables: GenericType[];
    substations: GenericType[];
    towers: GenericType[];
    "floating-equipment": GenericType[];
  } | null;
  equipmentFilterOptions: GenericType;
  equipmentSelectedFilter: GenericType;
  vesselFilterOptions: GenericType;
  vesselSelectedFilter: GenericType;
  vesselChartData: {
    "all-vessels": VesselContractChart[];
    installation: VesselContractChart[];
    "cable-lay": VesselContractChart[];
    "floating-installation": VesselContractChart[];
    maintenance: VesselContractChart[];
    "all-vessels-vessel-chart": VesselContractChart[];
    "installation-vessel-chart": VesselContractChart[];
    "cable-lay-vessel-chart": VesselContractChart[];
    "floating-installation-vessel-chart": VesselContractChart[];
    "maintenance-vessel-chart": VesselContractChart[];
    installationLeadTime: GenericType[];
    cableLayLeadTime: GenericType[];
  } | null;
  sortingInfo: SortingInfo;
};

const cableIds = [3, 31, 32];
const installationIds = [
  5, 9, 11, 13, 14, 15, 16, 19, 20, 21, 24, 17, 18, 48, 49,
];
const floatingIds = [1, 33, 26, 39, 4, 29, 8, 25, 28, 27];

const initialState: State = {
  equipmentIsLoading: true,
  vesselIsLoading: true,
  equipmentContract: null,
  vesselContract: null,
  equipmentFilterOptions: {},
  equipmentSelectedFilter: {},
  vesselFilterOptions: {},
  vesselSelectedFilter: {},
  vesselChartData: null,
  equipmentChartData: null,
  sortingInfo: {
    direction: "desc",
    name: "contractAwardDate",
  },
};

export const fetchEquipmentContractList = createAsyncThunk(
  "equipmentContract/list",
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const countries = state?.common?.countries;
      const { data } = await api.contract.getEquipmentContract();
      const turbines =
        data?.turbineContracts
          ?.filter(
            ({
              contract: {
                contract: { contract },
              },
            }: GenericType) =>
              contract?.equipmentTypes?.[0]?.type === "Turbine" &&
              contract?.contractWorkType?.id === 1,
          )
          ?.map(
            ({
              contract: {
                numOrdered,
                contract: { contract, windProjects },
                turbineModel,
              },
            }: GenericType) => ({
              contractAwardDate: contract?.awardDate
                ? moment(contract.awardDate).format("YYYY-MM-DD")
                : "",
              supplier: contract?.contractedCompanies
                ?.map((o: GenericType) => o?.contractee?.name)
                .join(", "),
              windFarm: windProjects
                .map((project: GenericType) => project.name)
                .join(", "),
              windFarmId: windProjects
                .map((project: GenericType) => project.windProjectId)
                .join(", "),
              country: [
                ...new Set(
                  windProjects
                    .map(
                      (project: GenericType) =>
                        countries?.find(
                          (country: Country) =>
                            country.id === project.countryId,
                        )?.name,
                    )
                    .filter(Boolean),
                ),
              ].join(", "),
              numberOfTurbines: numOrdered,
              turbineModelId: turbineModel?.id,
              turbineModel: turbineModel?.name,
              contractStatus: contract?.contractStatus?.status,
            }),
          ) || [];

      const cables =
        data?.cableContracts?.map(
          ({
            contract: {
              numOrdered,
              contract: { contract, windProjects },
              cable,
            },
            factory,
          }: GenericType) => ({
            contractAwardDate: contract?.awardDate
              ? moment(contract?.awardDate).format("YYYY-MM-DD")
              : "",
            supplier: contract?.contractedCompanies
              ?.map((o: GenericType) => o?.contractee?.name)
              .join(", "),
            windFarm: windProjects
              ?.map((project: GenericType) => project.name)
              .join(", "),
            windFarmId: windProjects
              ?.map((project: GenericType) => project.windProjectId)
              .join(", "),
            country: [
              ...new Set(
                windProjects
                  ?.map(
                    (project: GenericType) =>
                      countries?.find(
                        (country: Country) => country.id === project.countryId,
                      )?.name,
                  )
                  .filter(Boolean),
              ),
            ].join(", "),
            cableType: cable?.dynamic
              ? "Dynamic"
              : contract?.equipmentTypes?.[0].type,
            contractStatus: contract?.contractStatus?.status,
            factory: factory?.map((f: GenericType) => ({
              id: f.id,
              name: f.name,
            })),
            voltageKV: cable?.cableVoltageKv,
            outputCurrent: cable?.currentType?.type,
            numberOfCables: numOrdered,
            cableLengthKM: cable?.cableLengthKm,
          }),
        ) || [];

      const foundations =
        data?.foundationContracts?.map(
          ({
            contract: {
              fixedFoundationMetrics,
              contract: { contract, windProjects },
              numOrdered,
            },
            factory,
          }: GenericType) => ({
            contractAwardDate: contract?.awardDate
              ? moment(contract?.awardDate).format("YYYY-MM-DD")
              : "",
            supplier: contract?.contractedCompanies
              ?.map((o: GenericType) => o?.contractee?.name)
              .join(", "),
            windFarm: windProjects
              ?.map((project: GenericType) => project.name)
              .join(", "),
            windFarmId: windProjects
              ?.map((project: GenericType) => project.windProjectId)
              .join(", "),
            country: [
              ...new Set(
                windProjects
                  ?.map(
                    (project: GenericType) =>
                      countries?.find(
                        (country: Country) => country.id === project.countryId,
                      )?.name,
                  )
                  .filter(Boolean),
              ),
            ].join(", "),
            numberOfFoundations: numOrdered,
            foundationType: contract?.equipmentTypes?.[0]?.type,
            contractStatus: contract.contractStatus?.status,
            factory: factory?.map((f: GenericType) => ({
              id: f.id,
              name: f.name,
            })),
            foundationMass: fixedFoundationMetrics?.maxFoundationMassMt,
            foundationLength: fixedFoundationMetrics?.maxFoundationDepthM,
            foundationDiameter: fixedFoundationMetrics?.maxFoundationDiameterM,
            foundationThickness:
              fixedFoundationMetrics?.maxFoundationThicknessM,
          }),
        ) || [];

      const substations =
        data?.substationContracts?.map(
          ({
            contract: {
              contract: { contract, windProjects },
              outputCurrent,
              substation,
              voltage,
            },
            factory,
          }: GenericType) => ({
            contractAwardDate: contract?.awardDate
              ? moment(contract?.awardDate).format("YYYY-MM-DD")
              : "",
            supplier: contract?.contractedCompanies
              ?.map((o: GenericType) => o?.contractee?.name)
              .join(", "),
            windFarm: windProjects
              ?.map((project: GenericType) => project.name)
              .join(", "),
            windFarmId: windProjects
              ?.map((project: GenericType) => project.windProjectId)
              .join(", "),
            country: [
              ...new Set(
                windProjects
                  ?.map(
                    (project: GenericType) =>
                      countries?.find(
                        (country: Country) => country.id === project.countryId,
                      )?.name,
                  )
                  .filter(Boolean),
              ),
            ].join(", "),
            substation: substation?.name,
            components: contract?.equipmentTypes
              ?.map((e: GenericType) => e.type)
              .sort()
              .join(", "),
            contractStatus: contract?.contractStatus?.status,
            factory: factory?.map((f: GenericType) => ({
              id: f.id,
              name: f.name,
            })),
            topsidesMassMT: substation?.topsideMassMt,
            topsidesHeightM: substation?.topsideHeightM,
            topsidesWidthM: substation?.topsideWidthM,
            topsidesLengthM: substation?.topsideLengthM,
            foundationType: substation?.fixedFoundationType?.type,
            foundationMassMT: substation?.foundationMassMt,
            foundationHeightM: substation?.foundationHeightM,
            foundationWidthM: substation?.foundationWidthM,
            foundationLengthM: substation?.foundationLengthM,
            totalMassMT: substation?.totalMassMt,
            numberOfJacketLegs: substation?.foundationLegsNum,
            outputCurrent: outputCurrent?.type,
            voltageInKV: voltage?.arrayCableVoltage,
            voltageOutKV: voltage?.exportCableVoltage,
            factories: factory?.map((f: GenericType) => f.name)?.join(", "),
          }),
        ) || [];

      const towers =
        data?.towerContracts?.map(
          ({
            contract: {
              contract: { contract, windProjects },
              towerMetrics,
              numOrdered,
            },
            factory,
          }: GenericType) => ({
            contractAwardDate: contract?.awardDate
              ? moment(contract?.awardDate).format("YYYY-MM-DD")
              : "",
            supplier: contract?.contractedCompanies
              ?.map((o: GenericType) => o?.contractee?.name)
              .join(", "),
            windFarm: windProjects
              ?.map((project: GenericType) => project.name)
              .join(", "),
            windFarmId: windProjects
              ?.map((project: GenericType) => project.windProjectId)
              .join(", "),
            country: [
              ...new Set(
                windProjects
                  ?.map(
                    (project: GenericType) =>
                      countries?.find(
                        (country: Country) => country.id === project.countryId,
                      )?.name,
                  )
                  .filter(Boolean),
              ),
            ].join(", "),
            contractStatus: contract.contractStatus?.status,
            factory: factory?.map((f: GenericType) => ({
              id: f.id,
              name: f.name,
            })),
            numberOfTowers: numOrdered,
            numberOfSections: towerMetrics?.totalNumSections,
            sectionHeight: towerMetrics?.sectionHeightM,
            towerHeight: towerMetrics?.towerHeightM,
            towerMass: towerMetrics?.towerMassMt,
          }),
        ) || [];

      const floating =
        data?.floatingContracts
          ?.filter(
            ({
              contract: {
                contract: { contract },
              },
            }: GenericType) => [1, 5].includes(contract?.contractWorkType?.id),
          )
          ?.map(
            ({
              contract: {
                contract: { contract, windProjects },
                floatingTechnology,
              },
              factory,
            }: GenericType) => ({
              contractAwardDate: contract?.awardDate
                ? moment(contract?.awardDate).format("YYYY-MM-DD")
                : "",
              supplier: contract?.contractedCompanies
                ?.map((o: GenericType) => o?.contractee?.name)
                .join(", "),
              windFarm: windProjects
                ?.map((project: GenericType) => project.name)
                .join(", "),
              windFarmId: windProjects
                ?.map((project: GenericType) => project.windProjectId)
                .join(", "),
              country: [
                ...new Set(
                  windProjects
                    ?.map(
                      (project: GenericType) =>
                        countries?.find(
                          (country: Country) =>
                            country.id === project.countryId,
                        )?.name,
                    )
                    .filter(Boolean),
                ),
              ].join(", "),
              contractType: contract?.contractWorkType?.type,
              equipmentType: contract?.equipmentTypes
                ?.map((eq: IdTypePair) => eq.type)
                .join(", "),
              factory: factory?.map((f: GenericType) => ({
                id: f.id,
                name: f.name,
              })),
              contractStatus: contract?.contractStatus?.status,
              technologyType: floatingTechnology?.technology,
              floatingTechnologyId: floatingTechnology?.id,
            }),
          ) || [];

      const tableData = {
        turbines,
        cables,
        foundations,
        substations,
        towers,
        "floating-equipment": floating,
      };

      const baseList = ["supplier", "windFarm", "country", "contractStatus"];

      const turbineProperties = [...baseList, "turbineModel"];
      const foundationProperties = [...baseList, "foundationType"];
      const towersProperties = [...baseList];
      const cablesProperties = [
        ...baseList,
        "cableType",
        "voltageKV",
        "outputCurrent",
      ];
      const substationsProperties = [
        ...baseList,
        "substation",
        "components",
        "foundationType",
        "outputCurrent",
      ];
      const floatingEquipmentProperties = [...baseList, "technologyType"];
      return {
        data: tableData,
        filterOptions: {
          turbines: {
            contractAwardDate: generateDateFilter("contractAwardDate"),
            ...generateOptions(tableData.turbines, turbineProperties),
          },
          foundations: {
            contractAwardDate: generateDateFilter("contractAwardDate"),
            ...generateOptions(tableData.foundations, foundationProperties),
          },
          cables: {
            contractAwardDate: generateDateFilter("contractAwardDate"),
            ...generateOptions(tableData.cables, cablesProperties),
          },
          substations: {
            contractAwardDate: generateDateFilter("contractAwardDate"),
            ...generateOptions(tableData.substations, substationsProperties),
          },
          towers: {
            contractAwardDate: generateDateFilter("contractAwardDate"),
            ...generateOptions(tableData.towers, towersProperties),
          },
          "floating-equipment": {
            contractAwardDate: generateDateFilter("contractAwardDate"),
            ...generateOptions(
              tableData["floating-equipment"],
              floatingEquipmentProperties,
            ),
          },
        },
        selectedFilter: {},
      };
    } catch (err) {
      console.log(">>>", err);
    }
  },
);

export const fetchEquipmentContractChart = createAsyncThunk(
  "equipmentContractChart/list",
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const countries = state.common.countries;
      const { data } = await api.contract.getEquipmentContract();
      const turbinesGroupedContracts: GenericType = {};
      const foundationGroupedContracts: GenericType = {};
      const cableGroupedContracts: GenericType = {};
      const towerGroupedContracts: GenericType = {};
      const substationGroupedContracts: GenericType = {};
      const floatingGroupedContracts: GenericType = {};
      data.turbineContracts
        ?.filter(
          ({
            contract: {
              contract: { contract, windProjects },
            },
          }: GenericType) => {
            return (
              contract?.equipmentTypes?.[0]?.type === "Turbine" &&
              contract?.contractWorkType?.id === 1 &&
              windProjects?.every(
                (windProject: GenericType) => windProject?.countryId !== 155,
              )
            );
          },
        )
        .forEach(
          ({
            contract: {
              contract: { contract, windProjects },
              turbineModel,
            },
          }: GenericType) => {
            const awardDate = contract.awardDate
              ? moment(contract.awardDate).format("MMM YYYY")
              : null;
            windProjects?.forEach((windProject: GenericType) => {
              const operationDate = windProject?.operationDate
                ? moment(windProject.operationDate).format("MMM YYYY")
                : null;
              if (awardDate && operationDate) {
                if (!turbinesGroupedContracts[awardDate]) {
                  turbinesGroupedContracts[awardDate] = [];
                }
                turbinesGroupedContracts[awardDate].push({
                  ...contract,
                  awardDate: contract?.awardDate,
                  operationDate,
                  windProject,
                  turbineModel,
                });
              }
            });
          },
        );
      data.foundationContracts
        ?.filter(
          ({
            contract: {
              contract: { windProjects },
            },
          }: GenericType) =>
            windProjects?.every(
              (windProject: GenericType) => windProject?.countryId !== 155,
            ),
        )
        .forEach(
          ({
            contract: {
              contract: { contract, windProjects },
              turbineModel,
            },
          }: GenericType) => {
            const awardDate = contract.awardDate
              ? moment(contract.awardDate).format("MMM YYYY")
              : null;
            windProjects?.forEach((windProject: GenericType) => {
              const operationDate = windProject?.operationDate
                ? moment(windProject.operationDate).format("MMM YYYY")
                : null;
              if (awardDate && operationDate) {
                if (!foundationGroupedContracts[awardDate]) {
                  foundationGroupedContracts[awardDate] = [];
                }

                foundationGroupedContracts[awardDate].push({
                  ...contract,
                  awardDate: contract?.awardDate,
                  operationDate,
                  windProject,
                  turbineModel,
                });
              }
            });
          },
        );
      data.cableContracts
        ?.filter(
          ({
            contract: {
              contract: { windProjects },
            },
          }: GenericType) =>
            windProjects?.every(
              (windProject: GenericType) => windProject?.countryId !== 155,
            ),
        )
        .forEach(
          ({
            contract: {
              contract: { contract, windProjects },
              turbineModel,
            },
          }: GenericType) => {
            const awardDate = contract.awardDate
              ? moment(contract.awardDate).format("MMM YYYY")
              : null;
            windProjects?.forEach((windProject: GenericType) => {
              const operationDate = windProject?.operationDate
                ? moment(windProject.operationDate).format("MMM YYYY")
                : null;
              if (awardDate && operationDate) {
                if (!cableGroupedContracts[awardDate]) {
                  cableGroupedContracts[awardDate] = [];
                }

                cableGroupedContracts[awardDate].push({
                  ...contract,
                  awardDate: contract?.awardDate,
                  operationDate,
                  windProject,
                  turbineModel,
                });
              }
            });
          },
        );
      data.substationContracts
        ?.filter(
          ({
            contract: {
              contract: { windProjects },
            },
          }: GenericType) =>
            windProjects?.every(
              (windProject: GenericType) => windProject?.countryId !== 155,
            ),
        )
        .forEach(
          ({
            contract: {
              contract: { contract, windProjects },
              turbineModel,
            },
          }: GenericType) => {
            const awardDate = contract.awardDate
              ? moment(contract.awardDate).format("MMM YYYY")
              : null;
            windProjects?.forEach((windProject: GenericType) => {
              const operationDate = windProject?.operationDate
                ? moment(windProject.operationDate).format("MMM YYYY")
                : null;
              if (awardDate && operationDate) {
                if (!substationGroupedContracts[awardDate]) {
                  substationGroupedContracts[awardDate] = [];
                }

                substationGroupedContracts[awardDate].push({
                  ...contract,
                  awardDate: contract?.awardDate,
                  operationDate,
                  windProject,
                  turbineModel,
                });
              }
            });
          },
        );

      const filledInTurbines = objectFillerDates(turbinesGroupedContracts);
      const filledInFoundations = objectFillerDates(foundationGroupedContracts);
      const filledInCables = objectFillerDates(cableGroupedContracts);
      const filledInSubstations = objectFillerDates(substationGroupedContracts);
      const filledInFloating = objectFillerDates(floatingGroupedContracts);
      const filledInTowers = objectFillerDates(towerGroupedContracts);

      return {
        turbines: convertToDesiredArrayFormat(filledInTurbines, countries),
        foundations: convertToDesiredArrayFormat(
          filledInFoundations,
          countries,
          ["equipmentType"],
        ),
        cables: convertToDesiredArrayFormat(filledInCables, countries, [
          "equipmentType",
        ]),
        substations: convertToDesiredArrayFormat(
          filledInSubstations,
          countries,
          ["equipmentType"],
        ),
        towers: convertToDesiredArrayFormat(filledInTowers, countries),
        "floating-equipment": convertToDesiredArrayFormat(
          filledInFloating,
          countries,
        ),
      };
    } catch (err) {
      console.log(">>>", err);
    }
  },
);

export const fetchVesselContractList = createAsyncThunk(
  "vesselContract/list",
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const countries = state.common.countries;
      const equipmentTypes = state.common.equipmentType;
      const { data } = await api.contract.getVesselContract({});
      const installation: InstallationContract[] = [];
      const floating: FloatingInstallation[] = [];
      const cable: CableLay[] = [];
      const maintenance: GenericType[] = [];

      data.forEach((item: GenericType) => {
        item?.installation?.forEach((c: GenericType) => {
          if (c?.contract?.contractStatus?.status !== "Unallocated") {
            let tempCableType: GenericType[] = [];
            if (c?.windProject?.cableType.length > 0) {
              tempCableType = Object.assign({}, c?.windProject?.cableType);
            }
            const contractItem = {
              contractAwardDate: c?.contract?.awardDate
                ? moment(c?.contract?.awardDate).format("YYYY-MM-DD")
                : "",
              vesselName: item?.vessel?.vesselName,
              vesselOwner: c?.contract?.contractedCompanies?.map(
                (o: GenericType) => o?.contractee?.name,
              ),
              vesselImo: item?.vessel?.imo,
              windFarm: c?.windProject?.name,
              windFarmId: c?.windProject?.windProjectId,
              country: countries.find(
                (country: Country) => country.id === c?.windProject?.countryId,
              )?.name,
              startDate: c?.contract?.startDate
                ? moment(c?.contract?.startDate).format("YYYY-MM-DD")
                : "",
              endDate: c?.contract?.endDate
                ? moment(c?.contract?.endDate).format("YYYY-MM-DD")
                : "",
              turbineModel: c?.windProject?.turbineModels?.[0]?.name,
              turbineModelId: c?.windProject?.turbineModels?.[0]?.id,
              foundationType: c?.windProject?.fixedFoundationType
                ?.map((t: GenericType) => t.type)
                ?.join(", "),
              cableType:
                c?.windProject?.cableType.length > 0
                  ? tempCableType[0].type
                  : [],
              contractStatus: c?.contract?.contractStatus?.status,
              contractWorkTypeId: c?.contract?.contractWorkType?.id,
              contractWorkType: c?.contract?.contractWorkType?.type,
              equipmentType: c.contract?.equipmentTypeIds
                ?.map(
                  (id: number) =>
                    equipmentTypes.find((eq) => eq.id === id)?.type,
                )
                ?.join(", "),
              floatingTechnology:
                c?.windProject?.floatingTechnology?.[0]?.technology,
              floatingTechnologyId: c?.windProject?.floatingTechnology?.[0]?.id,
            };
            if (
              c.contract?.equipmentTypeIds?.some((id: number) =>
                cableIds.includes(id),
              )
            ) {
              cable.push(contractItem as unknown as CableLay);
            }
            if (
              c.contract?.equipmentTypeIds?.some((id: number) =>
                installationIds.includes(id),
              )
            ) {
              installation.push(contractItem);
            }
            if (
              c.contract?.equipmentTypeIds?.some((id: number) =>
                floatingIds.includes(id),
              )
            ) {
              if (contractItem?.contractWorkTypeId === 3) {
                floating.push(contractItem as unknown as FloatingInstallation);
              }
            }
          }
        });
        item?.maintenance?.forEach((c: GenericType) => {
          const contractItem = {
            contractAwardDate: c?.contract?.awardDate
              ? moment(c?.contract?.awardDate).format("YYYY-MM-DD")
              : "",
            vesselName: item?.vessel?.vesselName,
            vesselOwner: item?.vessel?.owners?.map(
              (v: GenericType) => v.companyName,
            ),
            vesselImo: item?.vessel?.imo,
            windFarm: c?.windProject?.name,
            windFarmId: c?.windProject?.windProjectId,
            country: countries.find(
              (country: Country) => country.id === c?.windProject?.countryId,
            )?.name,
            startDate: c?.contract?.startDate
              ? moment(c?.contract?.startDate).format("YYYY-MM-DD")
              : "",
            endDate: c?.contract?.endDate
              ? moment(c?.contract?.endDate).format("YYYY-MM-DD")
              : "",
            turbineModel: c?.windProject?.turbineModels?.[0]?.name,
            turbineModelId: c?.windProject?.turbineModels?.[0]?.id,
            contractWorkType: c?.contract?.contractWorkType?.type,
            foundationType: c?.windProject?.fixedFoundationType
              ?.map((t: GenericType) => t.type)
              ?.join(", "),
            contractStatus: c?.contract?.contractStatus?.status,
            equipmentType: c.contract?.equipmentTypeIds
              ?.map(
                (id: number) => equipmentTypes.find((eq) => eq.id === id)?.type,
              )
              ?.join(", "),
          };
          maintenance.push(contractItem);
        });
      });
      const mappedData = {
        installation,
        "all-vessels": [
          ...installation,
          ...floating,
          ...cable,
          ...maintenance,
        ] as AllVessels[],
        "cable-lay": cable,
        "floating-installation": floating,
        maintenance: maintenance,
      };
      const baseList = ["vesselName", "vesselOwner", "windFarm", "country"];
      const allVesselsProperties = [
        ...baseList,
        "turbineModel",
        "foundationType",
        "cableType",
        "contractEstimated",
      ];
      const installationProperties = [
        ...baseList,
        "turbineModel",
        "foundationType",
        "contractEstimated",
      ];
      const cableLayProperties = [
        ...baseList,
        "cableType",
        "contractEstimated",
      ];
      const floatingInstallationProperties = [
        ...baseList,
        "floatingTechnology",
      ];
      const maintenanceProperties = [
        ...baseList,
        "turbineModel",
        "foundationType",
      ];
      const tableData = {
        installation: installation?.map((i: GenericType) => ({
          ...i,
          vesselOwner: i?.vesselOwner?.join(", "),
        })),
        "all-vessels": [
          ...installation,
          ...floating,
          ...cable,
          ...maintenance,
        ]?.map((i: GenericType) => ({
          ...i,
          vesselOwner: i?.vesselOwner?.join(", "),
        })) as AllVessels[],
        "cable-lay": cable?.map((i: GenericType) => ({
          ...i,
          vesselOwner: i?.vesselOwner?.join(", "),
        })),
        "floating-installation": floating?.map((i: GenericType) => ({
          ...i,
          vesselOwner: i?.vesselOwner?.join(", "),
        })),
        maintenance: maintenance?.map((i: GenericType) => ({
          ...i,
          vesselOwner: i?.vesselOwner?.join(", "),
        })),
      };
      return {
        data: tableData,
        filterOptions: {
          "all-vessels": {
            contractAwardDate: generateDateFilter("contractAwardDate"),
            startDate: generateDateFilter("startDate"),
            endDate: generateDateFilter("endDate"),
            ...generateOptions(mappedData["all-vessels"], allVesselsProperties),
          },
          installation: {
            contractAwardDate: generateDateFilter("contractAwardDate"),
            startDate: generateDateFilter("startDate"),
            endDate: generateDateFilter("endDate"),
            ...generateOptions(mappedData.installation, installationProperties),
          },
          "cable-lay": {
            contractAwardDate: generateDateFilter("contractAwardDate"),
            startDate: generateDateFilter("startDate"),
            endDate: generateDateFilter("endDate"),
            ...generateOptions(mappedData["cable-lay"], cableLayProperties),
          },
          "floating-installation": {
            contractAwardDate: generateDateFilter("contractAwardDate"),
            startDate: generateDateFilter("startDate"),
            endDate: generateDateFilter("endDate"),
            ...generateOptions(
              mappedData["floating-installation"],
              floatingInstallationProperties,
            ),
          },
          maintenance: {
            contractAwardDate: generateDateFilter("contractAwardDate"),
            startDate: generateDateFilter("startDate"),
            endDate: generateDateFilter("endDate"),
            ...generateOptions(mappedData.maintenance, maintenanceProperties),
          },
        },
        selectedFilter: {},
      };
    } catch (err) {
      console.log(">>>>>>", err);
    }
  },
);

export const fetchVesselContractChart = createAsyncThunk(
  "vesselContract/chart",
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const countries = state.common.countries;
      const equipmentTypes = state.common.equipmentType;
      const { data } = await api.contract.getVesselContract({});
      const installations: GenericType[] = [];
      const installationList: GenericType = {};
      const installationVesselList: GenericType = {};
      const floatingList: GenericType = {};
      const floatingVesselList: GenericType = {};
      const cableList: GenericType = {};
      const cableVesselList: GenericType = {};
      const maintenanceList: GenericType = {};
      const maintenanceVesselList: GenericType = {};
      data.forEach((item: GenericType) => {
        item.installation.forEach((installation: GenericType) => {
          if (installation.contract) {
            installation.contract.vessel = item.vessel;
          }
        });
        installations.push(item.installation);
        item.installation?.forEach((c: GenericType) => {
          const status = findContractStatus(c);
          const project = c?.windProject;
          const projectId = project?.windProjectId;
          const vessel = item?.vessel?.vesselName;
          const vesselImo = item?.vessel?.imo;
          const contract = {
            contractAwardedDate: c?.contract?.awardDate,
            country: countries.find(
              (country: Country) => country.id === c?.windProject?.countryId,
            )?.name,
            countryId: c?.windProject?.countryId,
            startDate: c?.contract?.startDate,
            endDate: c?.contract?.endDate,
            foundationType: c?.windProject?.fixedFoundationType
              ?.map((t: GenericType) => t.type)
              .join(", "),
            status,
            contractStatus: c?.contract?.contractStatus?.status,
            turbineModel: c?.windProject?.turbineModels?.[0]?.name,
            windFarm: c?.windProject?.name,
            vessel,
          };
          if (
            contract.startDate &&
            contract.endDate &&
            status &&
            c?.contract?.contractStatus?.status !== "Unallocated"
          ) {
            // Function to push contract into the correct list
            const pushToList = (
              list: { [key: number]: GenericType },
              vesselList: { [key: number]: GenericType },
            ) => {
              if (list[projectId]) {
                list[projectId].contract.push(contract);
              } else {
                list[projectId] = {
                  id: projectId,
                  name: project?.name,
                  contract: [contract],
                };
              }
              if (vesselList[vesselImo]) {
                vesselList[vesselImo].contract.push(contract);
              } else {
                vesselList[vesselImo] = {
                  id: vesselImo,
                  name: vessel,
                  contract: [contract],
                };
              }
            };
            if (
              c.contract.equipmentTypeIds?.some((id: number) =>
                cableIds.includes(id),
              )
            ) {
              pushToList(cableList, cableVesselList);
            }
            if (
              c.contract.equipmentTypeIds?.some((id: number) =>
                installationIds.includes(id),
              )
            ) {
              pushToList(installationList, installationVesselList);
            }
            if (
              c.contract.equipmentTypeIds?.some((id: number) =>
                floatingIds.includes(id),
              )
            ) {
              pushToList(floatingList, floatingVesselList);
            }
          }
        });
        item.maintenance?.forEach((c: GenericType) => {
          const status = findContractStatus(c);
          if (status) {
            const project = c?.windProject;
            const projectId = project?.windProjectId;
            const vessel = item?.vessel?.vesselName;
            const vesselImo = item?.vessel?.imo;
            const contract = {
              contractAwardedDate: c?.contract?.awardDate,
              country: countries.find(
                (country: Country) => country.id === c?.windProject?.countryId,
              )?.name,
              countryId: c?.windProject?.countryId,
              startDate: c?.contract?.startDate,
              endDate: c?.contract?.endDate,
              foundationType: c?.windProject?.fixedFoundationType
                ?.map((t: GenericType) => t.type)
                .join(", "),
              status,
              turbineModel: c?.windProject?.turbineModels?.[0]?.name,
              windFarm: c?.windProject?.name,
              vessel,
            };
            if (maintenanceList[projectId]) {
              maintenanceList[projectId].contract.push(contract);
            } else {
              maintenanceList[projectId] = {
                id: projectId,
                name: project?.name,
                contract: [contract],
              };
            }
            if (maintenanceVesselList[vesselImo]) {
              maintenanceVesselList[vesselImo].contract.push(contract);
            } else {
              maintenanceVesselList[vesselImo] = {
                id: vesselImo,
                name: vessel,
                contract: [contract],
              };
            }
          }
        });
      });

      const installationsGroupedContracts: GenericType = {};
      const cableGroupedContracts: GenericType = {};

      installations.flat().forEach(({ contract, windProject }) => {
        const awardDate = contract?.awardDate
          ? moment(contract.awardDate).format("MMM YYYY")
          : null;

        if (
          contract?.equipmentTypeIds?.some((id: number) =>
            installationIds.includes(id),
          ) &&
          awardDate &&
          windProject?.operationDate
        ) {
          if (!installationsGroupedContracts[awardDate]) {
            installationsGroupedContracts[awardDate] = [];
          }
          if (contract?.contractStatus?.status !== "Unallocated") {
            installationsGroupedContracts[awardDate].push({
              ...contract,
              operationDate: windProject.operationDate,
              windProject,
              equipmentTypes: equipmentTypes?.filter((eq: GenericType) =>
                contract?.equipmentTypeIds?.includes(eq.id),
              ),
              vesselName: contract?.vessel?.vesselName,
            });
          }
        }

        if (
          contract?.equipmentTypeIds?.some((id: number) =>
            cableIds.includes(id),
          ) &&
          awardDate &&
          windProject?.operationDate
        ) {
          if (!cableGroupedContracts[awardDate]) {
            cableGroupedContracts[awardDate] = [];
          }
          cableGroupedContracts[awardDate].push({
            ...contract,
            operationDate: windProject.operationDate,
            windProject,
            equipmentTypes: equipmentTypes?.filter((eq: GenericType) =>
              contract?.equipmentTypeIds?.includes(eq.id),
            ),
            vesselName: contract?.vessel?.vesselName,
          });
        }
      });
      const installationTimeline = Object.values(installationList)
        .map((c) => ({ ...c, name: c.name || "N/A" }))
        .sort((a: VesselContractChart, b: VesselContractChart) =>
          a?.name.localeCompare(b?.name),
        );
      const cableTimeline = Object.values(cableList)
        .map((c) => ({ ...c, name: c.name || "N/A" }))
        .sort((a: VesselContractChart, b: VesselContractChart) =>
          a?.name.localeCompare(b?.name),
        );
      const floatingTimeline = Object.values(floatingList)
        .map((c) => ({ ...c, name: c.name || "N/A" }))
        .sort((a: VesselContractChart, b: VesselContractChart) =>
          a?.name.localeCompare(b?.name),
        );
      const maintenanceTimeline = Object.values(maintenanceList)
        .map((c) => ({ ...c, name: c.name || "N/A" }))
        .sort((a: VesselContractChart, b: VesselContractChart) =>
          a?.name.localeCompare(b?.name),
        );
      const installationVesselTimeline = Object.values(installationVesselList)
        .map((c) => ({ ...c, name: c.name || "N/A" }))
        .sort((a: VesselContractChart, b: VesselContractChart) =>
          a?.name.localeCompare(b?.name),
        );
      const cableVesselTimeline = Object.values(cableVesselList)
        .map((c) => ({ ...c, name: c.name || "N/A" }))
        .sort((a: VesselContractChart, b: VesselContractChart) =>
          a?.name.localeCompare(b?.name),
        );
      const floatingVesselTimeline = Object.values(floatingVesselList)
        .map((c) => ({ ...c, name: c.name || "N/A" }))
        .sort((a: VesselContractChart, b: VesselContractChart) =>
          a?.name.localeCompare(b?.name),
        );
      const maintenanceVesselTimeline = Object.values(maintenanceVesselList)
        .map((c) => ({ ...c, name: c.name || "N/A" }))
        .sort((a: VesselContractChart, b: VesselContractChart) =>
          a?.name.localeCompare(b?.name),
        );
      const filledInInstallations = objectFillerDates(
        installationsGroupedContracts,
      );
      const filledInCables = objectFillerDates(cableGroupedContracts);

      return {
        installation: installationTimeline,
        "all-vessels": [
          ...installationTimeline,
          ...cableTimeline,
          ...floatingTimeline,
          ...maintenanceTimeline,
        ],
        "cable-lay": cableTimeline,
        "floating-installation": floatingTimeline,
        maintenance: maintenanceTimeline,
        "all-vessels-vessel-chart": [
          ...installationVesselTimeline,
          ...cableVesselTimeline,
          ...floatingVesselTimeline,
          ...maintenanceVesselTimeline,
        ] as VesselContractChart[],
        "installation-vessel-chart":
          installationVesselTimeline as VesselContractChart[],
        "cable-lay-vessel-chart": cableVesselTimeline as VesselContractChart[],
        "floating-installation-vessel-chart":
          floatingVesselTimeline as VesselContractChart[],
        "maintenance-vessel-chart":
          maintenanceVesselTimeline as VesselContractChart[],
        installationLeadTime: convertToDesiredArrayFormat(
          filledInInstallations,
          countries,
          ["equipmentType", "vesselName"],
        ),
        cableLayLeadTime: convertToDesiredArrayFormat(
          filledInCables,
          countries,
          ["equipmentType", "vesselName"],
        ),
      };
    } catch (err) {
      console.log(">>>", err);
    }
  },
);

const contractSlice = createSlice({
  name: "contract",
  initialState,
  reducers: {
    setEquipmentContractSelectedFilter: (
      state,
      action: PayloadAction<GenericType>,
    ) => {
      state.equipmentSelectedFilter = {
        ...state.equipmentSelectedFilter,
        [action.payload.tab]: action.payload.filterValues,
      };
    },
    setVesselContractSelectedFilter: (
      state,
      action: PayloadAction<GenericType>,
    ) => {
      state.vesselSelectedFilter = {
        ...state.vesselSelectedFilter,
        [action.payload.tab]: action.payload.filterValues,
      };
    },
    setSortingInfo: (state, action: PayloadAction<SortingInfo>) => {
      state.sortingInfo = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchEquipmentContractList.pending, function (state) {
        state.equipmentIsLoading = true;
      })
      .addCase(fetchEquipmentContractList.rejected, (state) => {
        state.equipmentIsLoading = false;
        state.equipmentContract = null;
      })
      .addCase(fetchEquipmentContractList.fulfilled, (state, action) => {
        if (action.payload) {
          state.equipmentContract = action.payload.data;
          state.equipmentFilterOptions = action.payload.filterOptions;
          state.equipmentSelectedFilter = action.payload.selectedFilter;
        }
        state.equipmentIsLoading = false;
      });
    builder
      .addCase(fetchEquipmentContractChart.pending, function (state) {
        state.equipmentIsLoading = true;
      })
      .addCase(fetchEquipmentContractChart.rejected, (state) => {
        state.equipmentIsLoading = false;
        state.equipmentContract = null;
      })
      .addCase(fetchEquipmentContractChart.fulfilled, (state, action) => {
        if (action.payload) {
          state.equipmentChartData = action.payload;
        }
        state.equipmentIsLoading = false;
      });
    builder
      .addCase(fetchVesselContractList.pending, function (state) {
        state.vesselIsLoading = true;
      })
      .addCase(fetchVesselContractList.rejected, (state) => {
        state.vesselIsLoading = false;
        state.vesselContract = null;
      })
      .addCase(fetchVesselContractList.fulfilled, (state, action) => {
        if (action.payload) {
          state.vesselContract = action.payload
            .data as unknown as VesselContract;
          state.vesselFilterOptions = action.payload.filterOptions;
          state.vesselSelectedFilter = action.payload.selectedFilter;
        }
        state.vesselIsLoading = false;
      });
    builder
      .addCase(fetchVesselContractChart.pending, function (state) {
        state.vesselIsLoading = true;
      })
      .addCase(fetchVesselContractChart.rejected, (state) => {
        state.vesselIsLoading = false;
        state.vesselChartData = null;
      })
      .addCase(fetchVesselContractChart.fulfilled, (state, action) => {
        if (action.payload) {
          state.vesselChartData = action.payload;
          state.vesselIsLoading = false;
        }
      });
  },
});

export const {
  setEquipmentContractSelectedFilter,
  setVesselContractSelectedFilter,
  setSortingInfo,
} = contractSlice.actions;
export const contract = contractSlice.reducer;
