import { FilterItemType } from "../types";

import { FilterConfig } from "./filterBase";

export class DateFilter extends FilterConfig {
  constructor(config: { inputLabelText: string; format: string }) {
    super({
      type: FilterItemType.Date,
      inputLabelText: config.inputLabelText,
      labelKey: "value",
      format: config.format,
    });
  }

  getConfig() {
    return {
      type: this.type,
      inputLabelText: this.inputLabelText,
      labelKey: this.labelKey,
      format: this.format,
    };
  }
}
