import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  FormControl,
  getEsgianTheme,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@esgian/esgianui";
import {
  Chart,
  ChartOptions,
  ChartTypeRegistry,
  CoreScaleOptions,
  Plugin,
  Scale,
  TooltipModel,
} from "chart.js";
import html2canvas from "html2canvas"; // Import html2canvas
import moment from "moment";

import { ReactComponent as DownloadIcon } from "../../assets/export.svg";
import { parseNumberToSignificant } from "../../helper/numbers";
import { getThemeMode, getUser } from "../../store/selector/common";
import { FONT_FAMILY, GenericType, THEME, ThemeModeEnum } from "../../types";
import {
  ChartData as ChartDataType,
  ChartTimeSeriesDataSet,
  TimeSeriesData,
} from "../../types/charts";
import { KeyEventTimeline } from "../KeyEventsTimeline";

import { defaultTimeSeriesTooltip } from "./tooltips/TimeseriesTooltip";
import CanvasLegends from "./CanvasLegends";
import Chartjs from "./ChartJs";

type Series = {
  label: string;
  data: TimeSeriesData[];
};

type Prop = {
  series: Series[]; // Array of data series
  plugins?: Plugin<keyof ChartTypeRegistry, unknown>[]; // Chart.js plugins
  type: "line" | "bar"; // Chart type, restricts to "line" or "bar"
  loading?: boolean; // Optional loading state
  withKeyEvents?: boolean; // Optional loading state
  stackedBar?: boolean; // Optional flag for stacked bar chart
  id: string; // Unique identifier for the chart
  tooltipTitleFormat?: string; // Optional format string for tooltip titles
  customLabelRender?:
    | ((
        // Optional custom label renderer
        this: Scale<CoreScaleOptions>,
        tickValue: string | number,
        index: number,
      ) => string | number | null | undefined)
    | undefined;
  chartRef: React.RefObject<Chart | undefined>; // Reference to the Chart.js instance
  isCapFactor?: boolean;
};

const getOptions = (
  theme: GenericType,
  data: ChartDataType,
  maxYaxis: number,
  stackedBar: boolean = false,
  tooltipTitleFormat?: string,
  customLabelRender:
    | ((
        this: Scale<CoreScaleOptions>,
        tickValue: string | number,
        index: number,
      ) => string | number | null | undefined)
    | undefined = undefined,
  isCapFactor?: boolean,
): ChartOptions => {
  const {
    palette: {
      text: { primary },
      charts: { track },
    },
  } = theme;

  const categories: string[] = (data?.labels ?? []) as string[];
  let axisMax = maxYaxis;
  if (axisMax > 1000) {
    axisMax = axisMax * 1.1;
  }
  if (axisMax >= 3) {
    axisMax = Math.ceil(axisMax / 5) * 5;
  } else {
    axisMax = axisMax * 1.1;
  }

  let tempyAxisMax: number;
  if (stackedBar) {
    tempyAxisMax = maxYaxis * 2;
  } else if (isCapFactor) {
    tempyAxisMax = Math.round(maxYaxis / 10) * 10;
  } else {
    tempyAxisMax = maxYaxis;
  }

  return {
    responsive: true,
    transitions: {
      zoom: {
        animation: {
          duration: 0,
        },
      },
    },
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 30,
        left: 10,
      },
    },
    scales: {
      x: {
        min: 0,
        offset: false, // Remove gap before the first tick
        type: "category",
        labels: categories,
        ticks: {
          align: "start", // Align the first tick at the start
          callback: customLabelRender
            ? customLabelRender
            : function (
                this: Scale<CoreScaleOptions>,
                tickValue: string | number,
                index: number,
              ): string | number | null | undefined {
                const currentDate = moment(
                  this.getLabelForValue(tickValue as number),
                );

                if (index >= categories.length) {
                  currentDate.format("MMM DD");
                }
                const nextDate = moment(this.getLabelForValue(index + 1));
                let rollup = "hour";
                if (nextDate.diff(currentDate, "hours") < 1) {
                  rollup = "min";
                }

                if (currentDate.clone().hour() === 0 && rollup === "hour") {
                  return currentDate.format("MMM DD");
                }
                if (
                  currentDate.clone().startOf("day").isSame(currentDate) &&
                  rollup === "min"
                ) {
                  return currentDate.format("MMM DD");
                }
                return currentDate.format("YYYY:MM:DD");
              },
          color: primary,
          maxRotation: 45,
          minRotation: 45,
          font: {
            size: 10,
            family: FONT_FAMILY,
          },
        },
        grid: {
          display: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
      y: {
        stacked: stackedBar,
        border: {
          display: false,
        },
        grid: {
          tickLength: 0,
          color: track,
        },
        afterFit: function (axis: Scale<CoreScaleOptions>): void {
          const stepSize = `${Math.round((maxYaxis * 1.5) / 5) * 4}`.length;
          const size = stepSize < 4 ? 4 : stepSize;

          // Ensure 'axis' has a 'width' property; use a type assertion if necessary
          (axis as unknown as { width: number }).width = size * 8; // Safely set the width
        },
        ticks: {
          color: primary,
          font: {
            size: 10,
            family: ["Roboto", "helvetica", "Arial", "sans-serif"].join(","),
          },
          callback: (val) =>
            axisMax >= 5 ? parseInt(`${val}`) : parseNumberToSignificant(val),
          maxTicksLimit: isCapFactor ? undefined : 6, // +1 for zero
          stepSize: isCapFactor ? 10 : Math.round(axisMax / 5),
          count: 6,
          precision: 2,
          autoSkip: false,
        },
        min: 0,
        max: tempyAxisMax,
        type: "linear",
        position: "left",
        beginAtZero: true,
      },
    },
    elements: {
      line: {
        tension: 0,
      },
      point: {
        radius: 0,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {},
        enabled: false,
        position: "nearest",
        external: (context: {
          chart: Chart;
          tooltip: TooltipModel<keyof ChartTypeRegistry>;
        }) =>
          defaultTimeSeriesTooltip(
            context,
            data,
            theme.palette,
            tooltipTitleFormat,
          ),
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
          modifierKey: "ctrl",
        },
        limits: {
          x: { min: "original", max: "original", minRange: 20 },
        },
        zoom: {
          mode: "x",

          drag: {
            enabled: false,
          },
          pinch: {
            enabled: true,
          },
          wheel: {
            enabled: true,
            modifierKey: "ctrl",
          },
        },
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
  };
};

const CanvasTimeSeriesChart = ({
  customLabelRender,
  chartRef,
  withKeyEvents,
  plugins,
  series,
  tooltipTitleFormat,
  type,
  stackedBar,
  loading,
  id,
  isCapFactor,
}: Prop) => {
  const themeMode = useSelector(getThemeMode);
  const user = useSelector(getUser);
  const theme = getEsgianTheme(themeMode, THEME);

  const {
    palette: {
      charts: { tenColors },
    },
  } = theme;

  // Prepare data
  const data = useMemo(() => {
    if (!series.length) return { labels: [], datasets: [] };
    const labels: Record<string, null> = {}; // Explicitly typing labels

    // Collect unique timestamps
    series?.forEach(({ data: sData }) => {
      sData.forEach(({ x }: TimeSeriesData) => {
        labels[`${x}`] = null; // Store the timestamp as a key
      });
    });

    // Convert keys to numbers, sort, and then return them as strings
    const sortedLabels = Object.keys(labels)
      .map((key) => parseInt(key, 10)) // Convert keys to numbers
      .sort((a, b) => a - b) // Sort the numbers
      .map((timestamp) => timestamp);

    return {
      labels: sortedLabels,
      datasets:
        series?.map(
          (val: GenericType, i: number): ChartTimeSeriesDataSet => ({
            borderColor: tenColors[i % tenColors.length], // Use modulo to avoid index out of bounds
            borderWidth: 2,
            type: "line",
            spanGaps: true,
            pointHitRadius: 0,
            pointHoverRadius: 0,
            tension: 0,
            ...val,
            originalBackgroundColor: val.backgroundColor,
            label: val.label ?? "", // Ensure each dataset has a label
            data: val.data ?? [], // Pass data correctly
          }),
        ) ?? [],
    };
  }, [series, tenColors]);

  // Chart options
  const options = useMemo(() => {
    let maxYaxis = 5;
    if (data.datasets.length) {
      maxYaxis =
        Math.max(
          ...(series.flatMap((s) => s.data.map((d) => d.y || 0)) ?? 100),
        ) * 1.2;
    }
    return getOptions(
      theme,
      data,
      maxYaxis,
      stackedBar,
      tooltipTitleFormat,
      customLabelRender,
      isCapFactor,
    );
  }, [
    theme,
    data,
    stackedBar,
    tooltipTitleFormat,
    customLabelRender,
    series,
    isCapFactor,
  ]);

  const [selectedDownload, setSelectedDownload] = useState("");
  const containerRef = useRef<HTMLDivElement | null>(null);

  const saveAs = (blob: Blob, filename: string) => {
    const url = URL.createObjectURL(blob); // Create a URL for the Blob
    const link = document.createElement("a"); // Create a temporary <a> element
    link.href = url;
    link.download = filename; // Set the download filename
    link.click(); // Trigger the download
    URL.revokeObjectURL(url); // Release the URL to free memory
  };

  const handleExport = () => {
    if (selectedDownload === "PNG") {
      if (!containerRef.current) return;
      const originalBackground = containerRef.current.style.backgroundColor;
      const originalColor = containerRef.current.style.color;
      if (containerRef.current) {
        containerRef.current.style.backgroundColor =
          theme.palette.background.default;
        containerRef.current.style.color = theme.palette.text.primary;
      }

      html2canvas(containerRef.current).then((canvas) => {
        const base64Image = canvas.toDataURL("image/png"); // Convert canvas to base64 PNG
        const link = document.createElement("a");
        link.href = base64Image;
        link.download = "power-gen-chart.png"; // Set the download name
        link.click(); // Trigger the download
        if (containerRef.current) {
          containerRef.current.style.backgroundColor = originalBackground;
          containerRef.current.style.color = originalColor;
        }
      });
    }
    if (selectedDownload === "CSV") {
      if (!chartRef.current) return;
      const datePointData: Record<string, Record<string, number>> = {};
      const headers: string[] = [];
      headers.push("Date");
      data.datasets.forEach((dataset) => {
        const label = dataset.label || "-";
        headers.push(label);

        dataset.data.forEach((point: { x: number; y: number }) => {
          const date = moment(point.x).utc().format("YYYY-MM-DD HH:mm"); // Convert UNIX timestamp to readable date
          if (!datePointData[date]) {
            datePointData[date] = {};
          }
          datePointData[date][label] = point.y;
        });
      });
      // Create CSV rows
      const csvRows = [headers.join(",")]; // Add the header row
      Object.keys(datePointData).forEach((date) => {
        const row = [date];
        let hasData = false;
        headers.slice(1).forEach((header: string) => {
          if (datePointData[date][header]) {
            row.push(datePointData[date][header].toString()); // Fill Y value or leave blank
            hasData = true;
          } else {
            row.push("");
          }
        });
        if (hasData) {
          csvRows.push(row.join(","));
        }
      });
      // Convert rows to CSV format
      const csvContent = csvRows.join("\n");

      // Trigger download
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, "power-gen-chart.csv");
    }
    setSelectedDownload("");
  };

  useEffect(() => {
    if (selectedDownload !== "") {
      handleExport();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDownload]); // Run when selectedDownload changes

  const hasExportRight =
    Array.isArray(user?.profile?.permissionList) &&
    user?.profile?.permissionList?.includes("WIND_dataExports");

  return (
    <Stack sx={{ width: "auto" }} spacing={1}>
      {hasExportRight && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
          <Stack spacing={1} direction="row" alignItems="center">
            <DownloadIcon
              style={{
                width: "16px",
                height: "16px",
                fill:
                  themeMode === ThemeModeEnum.Dark
                    ? theme.palette.common.white
                    : theme.palette.common.black,
              }}
            />
            <Typography variant="body2">Download</Typography>
            <FormControl disabled={false} size="small">
              <Select
                variant="outlined"
                inputProps={{
                  sx: {
                    pl: 1,
                    pr: 1,
                    pt: 0.25,
                    pb: 0.25,
                  },
                }}
                value={selectedDownload}
                onChange={({ target }: GenericType) => {
                  setSelectedDownload(target.value);
                }}
                autoWidth="true"
              >
                <MenuItem value="PNG">
                  <Typography variant="body2">PNG</Typography>
                </MenuItem>
                <MenuItem value="CSV">
                  <Typography variant="body2">CSV</Typography>
                </MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Box>
      )}
      <Box ref={containerRef}>
        <CanvasLegends data={data} chartRef={chartRef} id={id} />
        {withKeyEvents && <KeyEventTimeline chartRef={chartRef} data={data} />}
        <Box sx={{ height: "35vh", width: "100%", position: "relative" }}>
          <Chartjs
            plugins={plugins}
            chartRef={chartRef}
            loading={loading}
            id={id}
            type={type}
            data={data}
            options={options}
          />
        </Box>
      </Box>
    </Stack>
  );
};

export default CanvasTimeSeriesChart;
