import { LoggingMessage } from "../../types/loggingMessage";
import { ApiMethodDeclaration } from "../types";

type ParamsData = LoggingMessage;
type ResponseData = undefined;

export const logMessage: ApiMethodDeclaration<ParamsData, ResponseData> = (
  data,
) => ({
  url: "/serilogging",
  method: "POST",
  data: [
    {
      level: data.level,
      renderedMessage: data.message,
      exception: data.exception,
      timestamp: new Date(),
      properties: data.properties,
    },
  ],
});
