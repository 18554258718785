import { FilterFactory } from "../../filterConfig/filterFactory";
import { sortValues } from "../../helper/fllter";
import { GenericType, RootState } from "../../types";
import {
  DATE_LABELS,
  DateConfig,
  DROPDOWN_LABELS,
  DropdownConfig,
} from "../../types/filters";

export const getProjectPPAList = (state: RootState) => {
  return state.ppaContract.ppaProjectList;
};

export const getSelectedFilter = (state: RootState) => {
  return state.ppaContract.selectedFilter;
};

const filterDuplicates = (data: GenericType[]) => {
  const ids: GenericType[] = [];
  const results: GenericType[] = [];
  data.forEach((item) => {
    if (!ids.includes(item.id)) {
      results.push(item);
      ids.push(item.id);
    }
  });
  return results;
};

export const getFilterOptions = (state: RootState) => {
  const filters = {
    project: {
      inputLabelText: "Wind project",
      options: sortValues(
        filterDuplicates(
          (state?.ppaContract?.ppaProjectList ?? [])
            .map((item) => item.project)
            .filter((value) => value != null && value !== "")
            .map((value) => ({ id: value, value })),
        ),
      ),
    },
    country: {
      inputLabelText: "Country",
      options: sortValues(
        filterDuplicates(
          (state?.ppaContract?.ppaProjectList ?? [])
            .map((item) => item.country)
            .filter((value) => value != null && value !== "")
            .map((value) => ({ id: value, value })),
        ),
      ),
    },
    projectStatus: {
      inputLabelText: "Wind project status",
      options: sortValues(
        filterDuplicates(
          (state?.ppaContract?.ppaProjectList ?? [])
            .map((item) => item.projectStatus)
            .filter((value) => value != null && value !== "")
            .map((value) => ({ id: value, value })),
        ),
      ),
    },
    ppaType: {
      inputLabelText: "PPA type",
      options: sortValues(
        filterDuplicates(
          (state?.ppaContract?.ppaProjectList ?? [])
            .map((item) => item.ppaType)
            .filter((value) => value != null && value !== "")
            .map((value) => ({ id: value, value })),
        ),
      ),
    },
    buyer: {
      inputLabelText: "Buyer",
      options: sortValues(
        filterDuplicates(
          (state?.ppaContract?.ppaProjectList ?? [])
            .map((item) => item.buyer)
            .filter((value) => value != null && value !== "")
            .map((value) => ({ id: value, value })),
        ),
      ),
    },
    seller: {
      inputLabelText: "Seller",
      options: sortValues(
        filterDuplicates(
          (state?.ppaContract?.ppaProjectList ?? [])
            .map((item) => item.seller)
            .filter((value) => value != null && value !== "")
            .map((value) => ({ id: value, value })),
        ),
      ),
    },
  };

  // Check if any filter has no options, and return null if so
  if (Object.values(filters).some((filter) => !filter.options?.length)) {
    return null;
  }

  const result: GenericType = {};
  Object.entries(filters).forEach(([key, filter]) => {
    if (
      DROPDOWN_LABELS.includes(filter.inputLabelText) &&
      "options" in filter
    ) {
      const dropdownFilter = filter as DropdownConfig;
      result[key] = FilterFactory.createFilter({
        inputLabelText:
          dropdownFilter.inputLabelText as DropdownConfig["inputLabelText"],
        options: sortValues(dropdownFilter.options),
      });
    } else if (
      DATE_LABELS.includes(filter.inputLabelText) &&
      "format" in filter
    ) {
      const dateFilter = filter as DateConfig;
      result[key] = FilterFactory.createFilter({
        inputLabelText:
          dateFilter.inputLabelText as DateConfig["inputLabelText"],
        format: dateFilter.format,
      });
    } else {
      result[key] = null;
    }
  });

  return result;
};

export const getIsLoading = (state: RootState) => {
  return state.ppaContract.isLoading;
};

export const getSortingInfo = (state: RootState) => {
  return state.ppaContract.sortingInfo;
};

export const getVisibleColumns = (state: RootState) => {
  return state.ppaContract.visibleColumns;
};
