import { FC, useEffect, useState } from "react";
import { Box, Divider, getEsgianTheme, Modal } from "@esgian/esgianui";

import { api } from "../../../api/api";
import { useSelector } from "../../../hooks/use-selector";
import { getCountries, getThemeMode } from "../../../store/selector/common";
import {
  Country,
  FloatingTechnologyInfo,
  GenericType,
  THEME,
  ThemeModeEnum,
} from "../../../types";
import { Loading } from "../../Loading";
import DropDownList from "../DropDownList";
import Paragraph from "../Paragraph";

const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);
  return {
    modalContainer: {
      "& .MuiDialog-container": {
        alignItems: "start",
        position: "absolute",
        right: 0,
        height: "auto",
        width: "22.5rem",
      },
      "& .MuiBackdrop-root": {
        opacity: "0 !important",
      },
      "& .MuiPaper-root": {
        margin: 0,
        width: 1,
        height: "100vh",
        borderRadius: 0,
        backgroundColor:
          themeMode === ThemeModeEnum.Dark
            ? theme.palette.common.black
            : theme.palette.common.white,
        backgroundImage: "none",
      },
      "& .MuiDialogContent-root": {
        padding: 0,
      },
    },
    divider: {
      my: 3,
    },
    contentBox: {
      py: 3,
      px: 2,
    },
    loadingContainer: {
      height: "calc(100vh - 66px)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
};

export const FloatingTechnologyDrawer: FC<{
  id?: number | null;
  onClose: () => void;
}> = ({ id, onClose }) => {
  const countries = useSelector(getCountries);
  const themeMode = useSelector(getThemeMode);
  const classes = useStyles(themeMode);
  const [data, setData] = useState<GenericType | null>(null);

  const fetchFloatingTechnologyData = async (floatingTechnologyId: number) => {
    try {
      const { data: floatingTechnologyResponse } =
        await api.floatingTechnology.getFloatingTechnology({
          ids: [floatingTechnologyId],
        });
      const resp: FloatingTechnologyInfo = floatingTechnologyResponse[0];

      const result = {
        keyInfo: {
          id: floatingTechnologyId,
          technology: resp?.floatingTechnology.technology,
          company: resp?.floatingTechnology?.suppliers
            .map((item) => item.companyName)
            .join(", "),
          floaterType: resp?.floatingTechnology?.floaterType?.type,
          floaterMaterial: resp?.floatingTechnology?.materialType?.material,
          minWaterDepth: resp?.floatingTechnology?.minWaterDepth,
          maxWaterDepth: resp?.floatingTechnology?.maxWaterDepth,
          turbinesPerFloating:
            resp?.floatingTechnology?.turbinesPerFloatingUnit,
        },
        projects: resp?.windProjects?.map((item) => ({
          ...item,
          title: item.name,
          projectCountry: countries?.find(
            (c: Country) => c.id === item?.countryId,
          )?.name,
          owner: item?.owners?.map((owner) => owner?.companyName).join(", "),
          projectStatus: item.projectStatus.status,
          maxWaterDepth: resp?.floatingTechnology?.maxWaterDepth,
          hydrogen: item.hydrogen ? "Yes" : "No",
        })),
      };
      setData(result);
    } catch (err) {
      onClose();
    }
  };

  useEffect(() => {
    setData(null);
    if (id) {
      fetchFloatingTechnologyData(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Modal
      sx={classes.modalContainer}
      open={!!id}
      size="sm"
      key={id}
      title={data ? data?.keyInfo?.technology : "Floating Technology"}
      handleClose={onClose}
    >
      {data ? (
        <Box sx={classes.contentBox}>
          <Paragraph
            title="Key Info"
            properties={{
              company: { key: "Company" },
              floaterType: { key: "Floater type" },
              floaterMaterial: { key: "Floater material" },
              minWaterDepth: { key: "Min water depth", unit: "m" },
              maxWaterDepth: { key: "Max water depth", unit: "m" },
              turbinesPerFloating: {
                key: "Turbines per floating",
                unit: "unit",
              },
            }}
            data={data.keyInfo}
          />
          <Divider sx={classes.divider} />
          {data?.projects?.length ? (
            <DropDownList
              title="Projects"
              subTitleKey="title"
              name="projects"
              data={data.projects}
              defaultExpand={true}
              properties={{
                name: { key: "Project" },
                projectCountry: { key: "Project country" },
                owner: { key: "Owner" },
                capacityMw: { key: "Capacity", unit: "MW" },
                projectStatus: { key: "Project Status" },
                maximumWaterDepth: { key: "Maximum water depth", unit: "m" },
                hydrogen: { key: "Hydrogen" },
              }}
            />
          ) : null}
        </Box>
      ) : (
        <Loading />
      )}
    </Modal>
  );
};
