import { useMemo } from "react";
import { Box, Table, Typography } from "@esgian/esgianui";

import { filterDataBasedOnDynamicKeys } from "../../helper/fllter";
import { isMatch } from "../../helper/isMatch";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getRowsPerPage,
  getSearchText,
  getThemeMode,
  getUser,
} from "../../store/selector/common";
import {
  getFloatingTechnologyList,
  getSelectedFilter,
  getSortingInfo,
} from "../../store/selector/floatingTechnology";
import {
  setRowsPerPage,
  setSelectedFloatingTechnologyId,
} from "../../store/slice/common";
import { setSortingInfo } from "../../store/slice/floatingTechnology";
import { FloatingTechnology, GenericType, IdNamePair } from "../../types";

export const FloatingTechnologyList = () => {
  const dispatch = useDispatch();
  const theme = useSelector(getThemeMode);
  const searchText = useSelector(getSearchText);
  const rowsPerPage = useSelector(getRowsPerPage);
  const sortingInfo = useSelector(getSortingInfo);
  const selectedFilter = useSelector(getSelectedFilter);
  const floatingTechnologyList = useSelector(getFloatingTechnologyList);
  const user = useSelector(getUser);

  const COLUMNS = useMemo(
    () => [
      {
        label: "Technology",
        name: "technology",
        options: {
          setCellProps: () => ({ style: { cursor: "pointer" } }),
          customBodyRender: (value: IdNamePair) => value || "N/A",
        },
      },
      {
        label: "Company",
        name: "company",
      },
      { label: "Floater type", name: "floaterType" },
      { label: "Floater material", name: "floaterMaterial" },
      { label: "Min water depth (m)", name: "minWaterDepth" },
      { label: "Max water depth (m)", name: "maxWaterDepth" },
      { label: "Turbines per floating unit", name: "turbinesPerFloatingUnit" },
    ],
    [],
  );

  const data = useMemo(() => {
    if (!floatingTechnologyList || !floatingTechnologyList?.length)
      return floatingTechnologyList;

    // eslint-disable-next-line no-unused-vars
    const { dateRange, ...listFIlter } = selectedFilter;
    let list = filterDataBasedOnDynamicKeys(
      floatingTechnologyList,
      listFIlter,
    ) as FloatingTechnology[];

    if (searchText?.length && list?.length) {
      list = list?.filter((item: FloatingTechnology) =>
        isMatch(item, [searchText]),
      );
    }
    return list;
  }, [searchText, floatingTechnologyList, selectedFilter]);

  const hasExportRight =
    Array.isArray(user?.profile?.permissionList) &&
    user?.profile?.permissionList?.includes("WIND_dataExports");

  return (
    <Box sx={{ px: 2 }}>
      {data ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body1" sx={{ py: 2 }}>
              {`Displaying ${data?.length} Floating Technology`}
            </Typography>
          </Box>
          <Table
            app="wind"
            columnDefs={[]}
            columns={COLUMNS}
            data={data}
            elevation={0}
            mode={theme}
            options={{
              download: hasExportRight,
              search: false,
              downloadOptions: {
                filename: `floating_technology_data.csv`,
              },
              expandableRows: false,
              filter: false,
              filterType: "multiselect",
              hideToolbarMargin: false,
              pagination: true,
              responsive: "standard",
              rowsPerPage,
              onChangeRowsPerPage: (numberOfRows: number) => {
                dispatch(setRowsPerPage(numberOfRows));
              },
              onRowClick: (_: number, rowMeta: GenericType) => {
                const { dataIndex }: GenericType = rowMeta;
                const item: GenericType = data[dataIndex];
                dispatch(setSelectedFloatingTechnologyId(item.id));
              },
              rowsPerPageOptions: [25, 50, 100],
              selectableRows: "none",
              sortOrder: sortingInfo,
              onColumnSortChange: (name: string, direction: string) => {
                dispatch(setSortingInfo({ name, direction }));
              },
              showResponsive: true,
              tableBodyHeight: "calc(100vh - 290px)",
              toolbar: true,
              print: false,
              viewColumns: true,
              onViewColumnsChange: () => {},
            }}
          />
        </>
      ) : null}
    </Box>
  );
};
