import { useEffect } from "react";
import { Box } from "@esgian/esgianui";

import { FactoryDrawer } from "../../components/Drawer/FactoryDrawer";
import { FactoriesList } from "../../components/FactoriesList";
import { FactoriesMap } from "../../components/FactoriesMap";
import { Loading } from "../../components/Loading";
import { FilterModal } from "../../components/Modal/FilterModal";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getFilterModal,
  getIsLookupLoading,
  getSelectedFactoryId,
  getViewTab,
} from "../../store/selector/common";
import {
  getFilterOptions,
  getSelectedFilter,
} from "../../store/selector/factory";
import {
  fetchLookup,
  setFilterModal,
  setSearchObjectCoordinates,
  setSearchText,
  setSelectedFactoryId,
  setViewTab,
} from "../../store/slice/common";
import {
  fetchFactoryDataList,
  fetchFactoryDataMap,
  setSelectedFilter,
} from "../../store/slice/factory";
import { ViewTabEnum } from "../../types";

export const Factory = () => {
  const dispatch = useDispatch();

  const viewTab = useSelector(getViewTab);
  const selectedFactoryId = useSelector(getSelectedFactoryId);
  const isFilterModalOpen = useSelector(getFilterModal);

  const options = useSelector((state) =>
    getFilterOptions(state, viewTab === ViewTabEnum.Map),
  );

  const selectedFilter = useSelector(getSelectedFilter);
  const isLookupLoading = useSelector(getIsLookupLoading);

  useEffect(() => {
    dispatch(fetchLookup(["factoryFunctions", "factoryStatus"]));
    dispatch(fetchLookup(["company"]));
    dispatch(fetchLookup(["equipmentType"]));
    dispatch(fetchFactoryDataMap());
    dispatch(fetchFactoryDataList());
  }, [dispatch]);

  useEffect(() => {
    if (viewTab !== ViewTabEnum.List && viewTab !== ViewTabEnum.Map) {
      dispatch(setViewTab(ViewTabEnum.Map));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setSearchText(undefined));
      dispatch(setSearchObjectCoordinates(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
      {!isLookupLoading ? (
        <>
          <FactoryDrawer
            id={selectedFactoryId}
            onClose={() => dispatch(setSelectedFactoryId(null))}
          />
          {options ? (
            <FilterModal
              open={isFilterModalOpen}
              handleClose={() => dispatch(setFilterModal(false))}
              onChange={(filterValues) => {
                dispatch(setSelectedFilter(filterValues));
              }}
              selectedFilter={selectedFilter}
              options={options}
            />
          ) : null}
          {viewTab === ViewTabEnum.Map ? <FactoriesMap /> : <FactoriesList />}
        </>
      ) : (
        <Loading />
      )}
    </Box>
  );
};
