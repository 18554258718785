import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@esgian/esgianui";

import { ProjectDrawer } from "../../components";
import { FilterModal } from "../../components/Modal/FilterModal";
import { PPAContractList } from "../../components/PPAContractList";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getCountries,
  getFilterModal,
  getSelectedProjectId,
} from "../../store/selector/common";
import {
  getFilterOptions,
  getSelectedFilter,
} from "../../store/selector/ppaContract";
import {
  setFilterModal,
  setSearchObjectCoordinates,
  setSearchText,
  setSelectedProjectId,
} from "../../store/slice/common";
import {
  fetchPPAContractList,
  setSelectedFilter,
} from "../../store/slice/ppaContract";
import { ROUTES_CONFIG } from "../../types";

export const PPAContract = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const options = useSelector(getFilterOptions);
  const countryList = useSelector(getCountries);
  const selectedProjectId = useSelector(getSelectedProjectId);
  const selectedFilter = useSelector(getSelectedFilter);
  const isFilterModalOpen = useSelector(getFilterModal);

  useEffect(() => {
    if (countryList.length) {
      dispatch(fetchPPAContractList());
    }
  }, [dispatch, countryList]);

  useEffect(() => {
    return () => {
      dispatch(setSearchText(undefined));
      dispatch(setSearchObjectCoordinates(null));
    };
  }, [dispatch]);

  const onLeaseRoundClick = (leaseRoundId: number) => {
    navigate(`${ROUTES_CONFIG.LeaseRoundTimeLineId.baseUrl}/${leaseRoundId}`);
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <ProjectDrawer
        id={selectedProjectId}
        onClose={() => dispatch(setSelectedProjectId(null))}
        onLeaseRoundClick={onLeaseRoundClick}
      />
      {options ? (
        <>
          <FilterModal
            open={isFilterModalOpen}
            handleClose={() => {
              dispatch(setFilterModal(false));
            }}
            onChange={(filterValues) => {
              dispatch(setSelectedFilter(filterValues));
            }}
            options={options}
            selectedFilter={selectedFilter}
          />
        </>
      ) : null}
      <PPAContractList />
    </Box>
  );
};
