import { useMemo } from "react";
import { Box, Table, Typography } from "@esgian/esgianui";

import {
  calculateFilterCount,
  filterListByDatePicker,
  filterListByDropdown,
  filterListByNumericSlider,
} from "../../helper/fllter";
import { isMatch } from "../../helper/isMatch";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getRowsPerPage,
  getSearchText,
  getThemeMode,
  getUser,
} from "../../store/selector/common";
import {
  getIsLoading,
  getProjectPPAList,
  getSelectedFilter,
  getSortingInfo,
  getVisibleColumns,
} from "../../store/selector/ppaContract";
import { setRowsPerPage, setSelectedProjectId } from "../../store/slice/common";
import {
  setSortingInfo,
  setVisibleColumns,
} from "../../store/slice/ppaContract";
import { GenericType, IdNamePair } from "../../types";
import { PPAProject } from "../../types/ppaProject";
import { FilterBtn } from "../FilterBtn";
import { Loading } from "../Loading";

export const PPAContractList = () => {
  const dispatch = useDispatch();
  const themeMode = useSelector(getThemeMode);
  const searchText = useSelector(getSearchText);
  const sortingInfo = useSelector(getSortingInfo);
  const projectList = useSelector(getProjectPPAList);
  const selectedFilter = useSelector(getSelectedFilter);
  const rowsPerPage = useSelector(getRowsPerPage);
  const visibleColumns = useSelector(getVisibleColumns);
  const isLoading = useSelector(getIsLoading);
  const user = useSelector(getUser);

  const COLUMNS = useMemo(
    () => [
      {
        label: "Wind project",
        name: "project",
        options: {
          display: visibleColumns.includes("project"),
          setCellProps: () => ({ style: { cursor: "pointer" } }),
          customBodyRender: (value: IdNamePair) => value || "N/A",
        },
      },
      {
        label: "Country",
        name: "country",
        options: { display: visibleColumns.includes("country") },
      },
      {
        label: "Wind project status",
        name: "projectStatus",
        options: { display: visibleColumns.includes("projectStatus") },
      },
      {
        label: "PPA type",
        name: "ppaType",
        options: { display: visibleColumns.includes("ppaType") },
      },
      {
        label: "Buyer",
        name: "buyer",
        options: { display: visibleColumns.includes("buyer") },
      },
      {
        label: "Seller",
        name: "seller",
        options: { display: visibleColumns.includes("seller") },
      },
      {
        label: "Size MW",
        name: "sizeMW",
        options: { display: visibleColumns.includes("sizeMW") },
      },
      {
        label: "Contract status",
        name: "contractStatus",
        options: { display: visibleColumns.includes("contractStatus") },
      },
      {
        label: "Duration years",
        name: "durationYears",
        options: { display: visibleColumns.includes("durationYears") },
      },
      {
        label: "Contract start",
        name: "contractStart",
        options: { display: visibleColumns.includes("contractStart") },
      },
      {
        label: "Contract end",
        name: "contractEnd",
        options: { display: visibleColumns.includes("contractEnd") },
      },
      {
        label: "Notes",
        name: "notes",
        options: { display: visibleColumns.includes("notes") },
      },
    ],
    [visibleColumns],
  );

  const data = useMemo(() => {
    if (!projectList || !projectList?.length) return [];
    let list = projectList;
    if (searchText?.length && list?.length) {
      list = list?.filter((item) => isMatch(item, [searchText]));
    }

    // Apply selected filter if available
    if (
      selectedFilter &&
      list &&
      Object.keys(selectedFilter).some((key) => selectedFilter[key].length > 0)
    ) {
      list = filterListByDropdown(list, selectedFilter) as PPAProject[];
      list = filterListByDatePicker(list, selectedFilter) as PPAProject[];
      list = filterListByNumericSlider(list, selectedFilter) as PPAProject[];
    }

    return list;
  }, [projectList, searchText, selectedFilter]);

  const hasExportRight =
    Array.isArray(user?.profile?.permissionList) &&
    user?.profile?.permissionList?.includes("WIND_dataExports");

  return (
    <Box sx={{ px: 2, maxWidth: "calc(100vw - 265px)" }}>
      {!isLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body1" sx={{ py: 2 }}>
              {`Displaying ${data?.length} PPA contracts`}
            </Typography>
            <FilterBtn count={calculateFilterCount(selectedFilter)} />
          </Box>

          <Table
            app="wind"
            columnDefs={[]}
            columns={COLUMNS}
            data={data}
            elevation={0}
            mode={themeMode}
            options={{
              download: hasExportRight,
              search: false,
              downloadOptions: {
                filename: `ppa_contracts_data.csv`,
              },
              expandableRows: false,
              filter: false,
              filterType: "multiselect",
              hideToolbarMargin: false,
              pagination: true,
              responsive: "vertical",
              rowsPerPage,
              onChangeRowsPerPage: (numberOfRows: number) => {
                dispatch(setRowsPerPage(numberOfRows));
              },
              onRowClick: (_: number, rowMeta: GenericType) => {
                const { dataIndex }: GenericType = rowMeta;
                const item: GenericType = data[dataIndex];
                dispatch(setSelectedProjectId(item.id));
              },
              rowsPerPageOptions: [25, 50, 100],
              selectableRows: "none",

              sortOrder: sortingInfo,
              onColumnSortChange: (name: string, direction: string) => {
                dispatch(setSortingInfo({ name, direction }));
              },
              showResponsive: true,
              tableBodyHeight: "calc(100vh - 310px)",
              toolbar: true,
              print: false,
              viewColumns: true,
              onViewColumnsChange: (c: string | string[]) => {
                dispatch(setVisibleColumns(c));
              },
            }}
          />
        </>
      ) : (
        <Loading />
      )}
    </Box>
  );
};
