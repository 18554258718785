import { FC, useMemo } from "react";
import { Box, CircularProgress, Table, Typography } from "@esgian/esgianui";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";

import { api } from "../../api/api";
import { safelyCallApi } from "../../helper/requestWrapper";
import { useSelector } from "../../hooks/use-selector";
import { usePowerGenQueries } from "../../hooks/useQueries/usePowerGenQueries";
import { getThemeMode, getUser } from "../../store/selector/common";
import {
  getPowerOutputCompareWindProjects,
  getPowerOutputTimePeriod,
  getPowerOutputWindProject,
} from "../../store/selector/powerOutput";
import { POWER_GEN_FILTERS } from "../../store/slice/powerOutput";
import { GenericType } from "../../types";

type Prop = {
  sx?: GenericType;
  selectedTab?: string;
};

const quarterToMonthMap: GenericType = {
  1: "Jan - Mar",
  2: "Apr - Jun",
  3: "Jul - Sep",
  4: "Oct - Dec",
};

const getQuarterPeriod = (val: string) => {
  const month = val.split(" ")[0];
  const year = val.split(" ")[3];
  return moment(`${month}-${year}`, "MMM-YYYY").valueOf();
};

export const PowerOutputCompareAdvancedProject: FC<Prop> = ({
  sx,
  selectedTab,
}) => {
  const themeMode = useSelector(getThemeMode);
  const { powerDataFullProjectQuery } = usePowerGenQueries();
  const selectedProject = useSelector(getPowerOutputWindProject);
  const selectedTimePeriod = useSelector(getPowerOutputTimePeriod);
  const selectedCompareItems = useSelector(getPowerOutputCompareWindProjects);
  const user = useSelector(getUser);

  const isCapacity = selectedTab === "capacity";

  const tableDataQuery = useQuery({
    queryKey: [
      "calendarTableData",
      {
        selectedTimePeriod,
        selectedProject,
        selectedCompareItems,
      },
    ],
    enabled: !!selectedProject && !!selectedTimePeriod,
    placeholderData: {},
    queryFn: () => {
      const ids: number[] = [];
      if (selectedProject) {
        ids.push(selectedProject.id);
      }
      if (selectedCompareItems.length) {
        selectedCompareItems.forEach(({ id }) => ids.push(id));
      }
      const historyRollup = selectedTimePeriod?.name.slice(0, -2).toLowerCase(); // Remove last 2 characters and make lowercase
      const body: GenericType = {};

      return safelyCallApi(
        api.powerOutputAggregated.getPowerOutputAggregated({
          projectIds: ids,
          includeHistorical: true,
          aggregationLevel: historyRollup === "hour" ? "hour" : "day",
          historyRollup: historyRollup,
          ...body,
        }),
      )
        .then(({ status, data }: GenericType) => {
          if (status !== 200) return {};
          const res: GenericType = {};
          const labels = new Set();
          ids.forEach((val) => {
            if (!val) return;
            const { projectId, historicalData, projectName } = data[val];
            if (!projectId || !historicalData) return;

            const tempSeries: GenericType = {
              capacityFactor: [],
              generationOutput: [],
              projectName: projectName ?? "",
            };

            Object.keys(historicalData).forEach((date: string) => {
              const values = historicalData[date];
              const dateValue = moment(date).valueOf();
              labels.add(dateValue);
              tempSeries.capacityFactor.push({
                date: dateValue,
                value: values.count ? values.capacityFactor : null,
              });
              tempSeries.generationOutput.push({
                date: dateValue,
                value: values.count ? values.generationOutput : null,
              });
            });
            tempSeries.capacityFactor = [...tempSeries.capacityFactor].sort(
              (a, b) => a.date - b.date,
            );
            tempSeries.generationOutput = [...tempSeries.generationOutput].sort(
              (a, b) => a.date - b.date,
            );

            res[projectId] = tempSeries;
          });

          return res;
        })
        .catch(() => {
          return {};
        });
    },
  });
  const tableData = useMemo(() => {
    if (!selectedProject || !selectedTimePeriod) return [];
    if (!tableDataQuery.data) return [];
    const result: GenericType[] = [];

    const queryData = tableDataQuery.data as GenericType;

    Object.keys(queryData).forEach((id: string) => {
      const { capacityFactor, generationOutput, projectName } = queryData[id];
      const value = isCapacity ? capacityFactor : generationOutput;

      const obj: GenericType = {};

      value.forEach((dateValuePair: GenericType) => {
        const dateTranslated = moment(dateValuePair.date);
        const month = dateTranslated.clone().format("MMM");
        const quarter = dateTranslated.clone().quarter();
        const year = dateTranslated.clone().year();
        let key = `${month} ${year}`; // Default monthly

        // Quarter
        if (
          selectedTimePeriod.id === POWER_GEN_FILTERS.CALENDAR_OPTIONS[1].id
        ) {
          key = `${quarterToMonthMap[quarter]} ${year}`;
        }
        // Yearly
        if (
          selectedTimePeriod.id === POWER_GEN_FILTERS.CALENDAR_OPTIONS[2].id
        ) {
          key = `${year}`;
        }
        if (!obj[key]) {
          obj[key] = { count: 0, value: 0 };
        }
        obj[key].count += 1;
        obj[key].value += dateValuePair.value ?? 0;
      });
      obj["name"] = projectName;

      // Push the processed object to the result array once per id
      result.push(obj);
    });

    return result;
  }, [selectedProject, selectedTimePeriod, tableDataQuery.data, isCapacity]);

  const COLUMNS = useMemo(() => {
    const fixedColumns = [
      {
        label: "Name",
        name: "name",
        options: {
          filter: true,
          sort: true,
        },
      },
    ];
    const allKeysTableData = new Set(
      tableData.flatMap((item) => Object.keys(item)),
    );
    let dynamicColumns: GenericType[] = [];
    allKeysTableData.forEach((item) => {
      if (item !== "name") {
        dynamicColumns.push({
          label: item,
          name: item,
          options: {
            customBodyRender: (obj: GenericType): string => {
              let value = obj?.value;
              if (isCapacity) {
                value = obj?.value / obj?.count;
              }
              if (value) {
                return `${Number(value).toFixed(2)} ${isCapacity ? " %" : ""}`;
              }
              return "";
            },
          },
        });
      }
    });
    const calOptions = POWER_GEN_FILTERS.CALENDAR_OPTIONS;
    dynamicColumns = dynamicColumns.sort((a: GenericType, b: GenericType) => {
      if (selectedTimePeriod?.id === calOptions[1].id) {
        return getQuarterPeriod(a.name) - getQuarterPeriod(b.name);
      }

      if (selectedTimePeriod?.id === calOptions[2].id) {
        return parseInt(a.name) - parseInt(b.name);
      }
      return (
        moment(a.name, "MMM YYYY").valueOf() -
        moment(b.name, "MMM YYYY").valueOf()
      );
    });

    return [...fixedColumns, ...dynamicColumns];
  }, [tableData, isCapacity, selectedTimePeriod]);

  let title = "";
  if (selectedTab === "capacity") {
    title = "Capacity factor";
  }
  if (selectedTab === "output") {
    title = "Power generation";
  }

  const hasExportRight =
    Array.isArray(user?.profile?.permissionList) &&
    user?.profile?.permissionList?.includes("WIND_dataExports");

  return (
    <Box
      sx={{
        background: ({ palette }: GenericType) => palette.background.paper,
        p: 2,
        height: "100%",
        borderRadius: 4,
        ...sx,
      }}
    >
      <Typography variant="h6">
        {title} overview - {selectedTimePeriod?.name ?? ""}
      </Typography>
      {powerDataFullProjectQuery.isFetched ? (
        <Box sx={{ overflowX: "auto" }}>
          <Table
            app="wind"
            columnDefs={[]}
            columns={COLUMNS}
            data={tableData}
            elevation={0}
            mode={themeMode}
            options={{
              download: hasExportRight,
              search: false,
              downloadOptions: {
                filename: `turbine_models_data.csv`,
              },
              expandableRows: false,
              filter: false,
              hideToolbarMargin: false,
              pagination: false,
              responsive: "standard",
              selectableRows: "none",
              showResponsive: false,
              toolbar: true,
              print: false,
              viewColumns: false,
            }}
          />
        </Box>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};
