import { safelyCallApi } from "../../helper/requestWrapper";
import { GenericType } from "../../types";
import { api } from "../api";

function logMessage(
  level: string,
  message: string,
  error?: GenericType,
  properties?: GenericType,
) {
  const reqObject = {
    level: level,
    message: message,
    exception: JSON.stringify(error),
    properties: properties,
  };

  safelyCallApi(api.loggingService.logMessage(reqObject));
}

function logInfo(
  message: string,
  error?: GenericType,
  properties?: GenericType,
) {
  logMessage("Information", message, error, properties);
}

function logEvent(
  message: string,
  error?: GenericType,
  properties?: GenericType,
) {
  logMessage("Event", message, error, properties);
}

function logError(
  error?: GenericType,
  message?: string,
  properties?: GenericType,
) {
  logMessage("Error", message ?? "Error was thrown", error, properties);
}

function logWarn(
  message: string,
  error?: GenericType,
  properties?: GenericType,
) {
  logMessage("Warning", message, error, properties);
}

export { logError, logEvent, logInfo, logMessage, logWarn };
